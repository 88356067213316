@import "assets/variables";

.ViewVacanciesOverview {
  &.hide-filters .View__filters {
    display: none;
  }

  .row {
    row-gap: 3.2rem;

    @media #{$tablet} {
      row-gap: 2.4rem;
    }

    @media #{$normal} {
      row-gap: 4rem;
    }
  }
}