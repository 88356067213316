@use "sass:math";
@use "sass:map";

@import "assets/variables";
@import "bootstrap/scss/mixins";

// -----------------------------------------------------------------------------
//
// Grid utilities
//
// -----------------------------------------------------------------------------
// Override bootstrap grid mixins to allow different gutters on each breakpoint.
// -----------------------------------------------------------------------------

/* stylelint-disable */

.container {
  &.container--small {
    max-width: map.get($container-max-widths, "sm");

    @media #{$tablet} {
      max-width: map.get($container-max-widths, "md");
    }

    @media #{$normal} {
      max-width: 81rem;
    }
  }
  &.container--medium {
    // 95rem + 5rem gutter
    max-width: 100rem;
  }
  &.container--large {
    // 180rem + 5rem gutter
    max-width: 185rem;
  }
}

@mixin make-container($gutters: $grid-gutter-widths) {
  position: relative;
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map.get($gutters, $breakpoint);

      padding-right: math.div($gutter, 2);
      padding-left: math.div($gutter, 2);
    }
  }
}

@mixin make-gutters($gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map.get($gutters, $breakpoint);

      padding-right: math.div($gutter, 2);
      padding-left: math.div($gutter, 2);
    }
  }
}

@mixin make-row($sides: left-right, $gutters: $grid-gutter-widths) {
  display: flex;
  flex-wrap: wrap;

  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map.get($gutters, $breakpoint);

      @if $sides == all {
        margin: math.div($gutter, -2);
      } @else if $sides == left-right {
        margin-right: math.div($gutter, -2);
        margin-left: math.div($gutter, -2);
      } @else if $sides == top-bottom {
        margin-top: math.div($gutter, -2);
        margin-bottom: math.div($gutter, -2);
      }
    }
  }
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty

    @each $breakpoint in map-keys($grid-gutter-widths) {
      @include media-breakpoint-up($breakpoint) {
        $gutter: map.get($grid-gutter-widths, $breakpoint);

        padding-right: math.div($gutter, 2);
        padding-left: math.div($gutter, 2);
      }
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not($infix == "" and $i == 0) {
          // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }

    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }
  }
}

// -----------------------------------------------------------------------------
//
// Responsive utilities
//
// -----------------------------------------------------------------------------
// Define mixins that are based on the global gutter variables.
// -----------------------------------------------------------------------------
@mixin padding($divider: 1) {
  @if $divider > 0 {
    padding: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      padding: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      padding: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      padding: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      padding: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      padding: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    padding: 0;

    @media #{$mobile} {
      padding: 0;
    }

    @media #{$tablet} {
      padding: 0;
    }

    @media #{$normal} {
      padding: 0;
    }

    @media #{$wide} {
      padding: 0;
    }

    @media #{$huge} {
      padding: 0;
    }
  }
}

@mixin padding-left($divider: 1) {
  @if $divider > 0 {
    padding-left: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      padding-left: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      padding-left: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      padding-left: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      padding-left: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      padding-left: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    padding-left: 0;

    @media #{$mobile} {
      padding-left: 0;
    }

    @media #{$tablet} {
      padding-left: 0;
    }

    @media #{$normal} {
      padding-left: 0;
    }

    @media #{$wide} {
      padding-left: 0;
    }

    @media #{$huge} {
      padding-left: 0;
    }
  }
}

@mixin padding-right($divider: 1) {
  @if $divider > 0 {
    padding-right: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      padding-right: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      padding-right: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      padding-right: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      padding-right: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      padding-right: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    padding-right: 0;

    @media #{$mobile} {
      padding-right: 0;
    }

    @media #{$tablet} {
      padding-right: 0;
    }

    @media #{$normal} {
      padding-right: 0;
    }

    @media #{$wide} {
      padding-right: 0;
    }

    @media #{$huge} {
      padding-right: 0;
    }
  }
}

@mixin padding-top($divider: 1) {
  @if $divider > 0 {
    padding-top: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      padding-top: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      padding-top: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      padding-top: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      padding-top: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      padding-top: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    padding-top: 0;

    @media #{$mobile} {
      padding-top: 0;
    }

    @media #{$tablet} {
      padding-top: 0;
    }

    @media #{$normal} {
      padding-top: 0;
    }

    @media #{$wide} {
      padding-top: 0;
    }

    @media #{$huge} {
      padding-top: 0;
    }
  }
}

@mixin padding-bottom($divider: 1) {
  @if $divider > 0 {
    padding-bottom: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      padding-bottom: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      padding-bottom: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      padding-bottom: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      padding-bottom: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      padding-bottom: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    padding-bottom: 0;

    @media #{$mobile} {
      padding-bottom: 0;
    }

    @media #{$tablet} {
      padding-bottom: 0;
    }

    @media #{$normal} {
      padding-bottom: 0;
    }

    @media #{$wide} {
      padding-bottom: 0;
    }

    @media #{$huge} {
      padding-bottom: 0;
    }
  }
}

@mixin padding-left-right($divider: 1) {
  padding-right: math.div($grid-xs-gutter-width-base, $divider);
  padding-left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    padding-right: math.div($grid-sm-gutter-width-base, $divider);
    padding-left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    padding-right: math.div($grid-md-gutter-width-base, $divider);
    padding-left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    padding-right: math.div($grid-lg-gutter-width-base, $divider);
    padding-left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    padding-right: math.div($grid-xl-gutter-width-base, $divider);
    padding-left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    padding-right: math.div($grid-xxl-gutter-width-base, $divider);
    padding-left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin padding-top-bottom($divider: 1) {
  padding-top: math.div($grid-xs-gutter-width-base, $divider);
  padding-bottom: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    padding-top: math.div($grid-sm-gutter-width-base, $divider);
    padding-bottom: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    padding-top: math.div($grid-md-gutter-width-base, $divider);
    padding-bottom: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    padding-top: math.div($grid-lg-gutter-width-base, $divider);
    padding-bottom: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    padding-top: math.div($grid-xl-gutter-width-base, $divider);
    padding-bottom: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    padding-top: math.div($grid-xxl-gutter-width-base, $divider);
    padding-bottom: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin padding-left-top($divider: 1) {
  padding-top: math.div($grid-xs-gutter-width-base, $divider);
  padding-left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    padding-top: math.div($grid-sm-gutter-width-base, $divider);
    padding-left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    padding-top: math.div($grid-md-gutter-width-base, $divider);
    padding-left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    padding-top: math.div($grid-lg-gutter-width-base, $divider);
    padding-left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    padding-top: math.div($grid-xl-gutter-width-base, $divider);
    padding-left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$wide} {
    padding-top: math.div($grid-xxl-gutter-width-base, $divider);
    padding-left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin padding-left-bottom($divider: 1) {
  padding-bottom: math.div($grid-xs-gutter-width-base, $divider);
  padding-left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    padding-bottom: math.div($grid-sm-gutter-width-base, $divider);
    padding-left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    padding-bottom: math.div($grid-md-gutter-width-base, $divider);
    padding-left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    padding-bottom: math.div($grid-lg-gutter-width-base, $divider);
    padding-left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    padding-bottom: math.div($grid-xl-gutter-width-base, $divider);
    padding-left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    padding-bottom: math.div($grid-xxl-gutter-width-base, $divider);
    padding-left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin padding-right-top($divider: 1) {
  padding-top: math.div($grid-xs-gutter-width-base, $divider);
  padding-right: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    padding-top: math.div($grid-sm-gutter-width-base, $divider);
    padding-right: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    padding-top: math.div($grid-md-gutter-width-base, $divider);
    padding-right: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    padding-top: math.div($grid-lg-gutter-width-base, $divider);
    padding-right: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    padding-top: math.div($grid-xl-gutter-width-base, $divider);
    padding-right: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    padding-top: math.div($grid-xxl-gutter-width-base, $divider);
    padding-right: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin padding-right-bottom($divider: 1) {
  padding-right: math.div($grid-xs-gutter-width-base, $divider);
  padding-bottom: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    padding-right: math.div($grid-sm-gutter-width-base, $divider);
    padding-bottom: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    padding-right: math.div($grid-md-gutter-width-base, $divider);
    padding-bottom: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    padding-right: math.div($grid-lg-gutter-width-base, $divider);
    padding-bottom: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    padding-right: math.div($grid-xl-gutter-width-base, $divider);
    padding-bottom: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    padding-right: math.div($grid-xxl-gutter-width-base, $divider);
    padding-bottom: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

///////////////////////

@mixin margin($divider: 1) {
  @if $divider > 0 {
    margin: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      margin: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      margin: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      margin: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      margin: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      margin: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    margin: 0;

    @media #{$mobile} {
      margin: 0;
    }

    @media #{$tablet} {
      margin: 0;
    }

    @media #{$normal} {
      margin: 0;
    }

    @media #{$wide} {
      margin: 0;
    }

    @media #{$huge} {
      margin: 0;
    }
  }
}

@mixin margin-left($divider: 1) {
  @if $divider > 0 {
    margin-left: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      margin-left: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      margin-left: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      margin-left: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      margin-left: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      margin-left: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    margin-left: 0;

    @media #{$mobile} {
      margin-left: 0;
    }

    @media #{$tablet} {
      margin-left: 0;
    }

    @media #{$normal} {
      margin-left: 0;
    }

    @media #{$wide} {
      margin-left: 0;
    }

    @media #{$huge} {
      margin-left: 0;
    }
  }
}

@mixin margin-right($divider: 1) {
  @if $divider > 0 {
    margin-right: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      margin-right: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      margin-right: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      margin-right: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      margin-right: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      margin-right: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    margin-right: 0;

    @media #{$mobile} {
      margin-right: 0;
    }

    @media #{$tablet} {
      margin-right: 0;
    }

    @media #{$normal} {
      margin-right: 0;
    }

    @media #{$wide} {
      margin-right: 0;
    }

    @media #{$huge} {
      margin-right: 0;
    }
  }
}

@mixin margin-top($divider: 1) {
  @if $divider > 0 {
    margin-top: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      margin-top: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      margin-top: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      margin-top: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      margin-top: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      margin-top: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    margin-top: 0;

    @media #{$mobile} {
      margin-top: 0;
    }

    @media #{$tablet} {
      margin-top: 0;
    }

    @media #{$normal} {
      margin-top: 0;
    }

    @media #{$wide} {
      margin-top: 0;
    }

    @media #{$huge} {
      margin-top: 0;
    }
  }
}

@mixin margin-bottom($divider: 1) {
  @if $divider > 0 {
    margin-bottom: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      margin-bottom: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      margin-bottom: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      margin-bottom: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      margin-bottom: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      margin-bottom: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    margin-bottom: 0;

    @media #{$mobile} {
      margin-bottom: 0;
    }

    @media #{$tablet} {
      margin-bottom: 0;
    }

    @media #{$normal} {
      margin-bottom: 0;
    }

    @media #{$wide} {
      margin-bottom: 0;
    }

    @media #{$huge} {
      margin-bottom: 0;
    }
  }
}

@mixin margin-left-right($divider: 1) {
  margin-right: math.div($grid-xs-gutter-width-base, $divider);
  margin-left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    margin-right: math.div($grid-sm-gutter-width-base, $divider);
    margin-left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    margin-right: math.div($grid-md-gutter-width-base, $divider);
    margin-left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    margin-right: math.div($grid-lg-gutter-width-base, $divider);
    margin-left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    margin-right: math.div($grid-xl-gutter-width-base, $divider);
    margin-left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    margin-right: math.div($grid-xxl-gutter-width-base, $divider);
    margin-left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin margin-top-bottom($divider: 1) {
  margin-top: math.div($grid-xs-gutter-width-base, $divider);
  margin-bottom: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    margin-top: math.div($grid-sm-gutter-width-base, $divider);
    margin-bottom: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    margin-top: math.div($grid-md-gutter-width-base, $divider);
    margin-bottom: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    margin-top: math.div($grid-lg-gutter-width-base, $divider);
    margin-bottom: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    margin-top: math.div($grid-xl-gutter-width-base, $divider);
    margin-bottom: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    margin-top: math.div($grid-xxl-gutter-width-base, $divider);
    margin-bottom: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin margin-left-top($divider: 1) {
  margin-top: math.div($grid-xs-gutter-width-base, $divider);
  margin-left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    margin-top: math.div($grid-sm-gutter-width-base, $divider);
    margin-left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    margin-top: math.div($grid-md-gutter-width-base, $divider);
    margin-left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    margin-top: math.div($grid-lg-gutter-width-base, $divider);
    margin-left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    margin-top: math.div($grid-xl-gutter-width-base, $divider);
    margin-left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$wide} {
    margin-top: math.div($grid-xxl-gutter-width-base, $divider);
    margin-left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin margin-left-bottom($divider: 1) {
  margin-bottom: math.div($grid-xs-gutter-width-base, $divider);
  margin-left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    margin-bottom: math.div($grid-sm-gutter-width-base, $divider);
    margin-left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    margin-bottom: math.div($grid-md-gutter-width-base, $divider);
    margin-left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    margin-bottom: math.div($grid-lg-gutter-width-base, $divider);
    margin-left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    margin-bottom: math.div($grid-xl-gutter-width-base, $divider);
    margin-left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    margin-bottom: math.div($grid-xxl-gutter-width-base, $divider);
    margin-left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin margin-right-top($divider: 1) {
  margin-top: math.div($grid-xs-gutter-width-base, $divider);
  margin-right: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    margin-top: math.div($grid-sm-gutter-width-base, $divider);
    margin-right: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    margin-top: math.div($grid-md-gutter-width-base, $divider);
    margin-right: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    margin-top: math.div($grid-lg-gutter-width-base, $divider);
    margin-right: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    margin-top: math.div($grid-xl-gutter-width-base, $divider);
    margin-right: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    margin-top: math.div($grid-xxl-gutter-width-base, $divider);
    margin-right: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin margin-right-bottom($divider: 1) {
  margin-right: math.div($grid-xs-gutter-width-base, $divider);
  margin-bottom: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    margin-right: math.div($grid-sm-gutter-width-base, $divider);
    margin-bottom: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    margin-right: math.div($grid-md-gutter-width-base, $divider);
    margin-bottom: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    margin-right: math.div($grid-lg-gutter-width-base, $divider);
    margin-bottom: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    margin-right: math.div($grid-xl-gutter-width-base, $divider);
    margin-bottom: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    margin-right: math.div($grid-xxl-gutter-width-base, $divider);
    margin-bottom: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

///////////////////////////

@mixin top-right-bottom-left($divider: 1) {
  inset: math.div($grid-xs-gutter-width-base, $divider) math.div($grid-xs-gutter-width-base, $divider)
    math.div($grid-xs-gutter-width-base, $divider) math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    inset: math.div($grid-sm-gutter-width-base, $divider) math.div($grid-sm-gutter-width-base, $divider)
      math.div($grid-sm-gutter-width-base, $divider) math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    inset: math.div($grid-md-gutter-width-base, $divider) math.div($grid-md-gutter-width-base, $divider)
      math.div($grid-md-gutter-width-base, $divider) math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    inset: math.div($grid-lg-gutter-width-base, $divider) math.div($grid-lg-gutter-width-base, $divider)
      math.div($grid-lg-gutter-width-base, $divider) math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    inset: math.div($grid-xl-gutter-width-base, $divider) math.div($grid-xl-gutter-width-base, $divider)
      math.div($grid-xl-gutter-width-base, $divider) math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    inset: math.div($grid-xxl-gutter-width-base, $divider) math.div($grid-xxl-gutter-width-base, $divider)
      math.div($grid-xxl-gutter-width-base, $divider) math.div($grid-xxl-gutter-width-base, $divider);
  }
}

// right: auto;
@mixin top-bottom-left($divider: 1) {
  top: math.div($grid-xs-gutter-width-base, $divider);
  bottom: math.div($grid-xs-gutter-width-base, $divider);
  left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    top: math.div($grid-sm-gutter-width-base, $divider);
    bottom: math.div($grid-sm-gutter-width-base, $divider);
    left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    top: math.div($grid-md-gutter-width-base, $divider);
    bottom: math.div($grid-md-gutter-width-base, $divider);
    left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    top: math.div($grid-lg-gutter-width-base, $divider);
    bottom: math.div($grid-lg-gutter-width-base, $divider);
    left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    top: math.div($grid-xl-gutter-width-base, $divider);
    bottom: math.div($grid-xl-gutter-width-base, $divider);
    left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    top: math.div($grid-xxl-gutter-width-base, $divider);
    bottom: math.div($grid-xxl-gutter-width-base, $divider);
    left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

// left: auto;
@mixin top-right-bottom($divider: 1) {
  top: math.div($grid-xs-gutter-width-base, $divider);
  right: math.div($grid-xs-gutter-width-base, $divider);
  bottom: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    top: math.div($grid-sm-gutter-width-base, $divider);
    right: math.div($grid-sm-gutter-width-base, $divider);
    bottom: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    top: math.div($grid-md-gutter-width-base, $divider);
    right: math.div($grid-md-gutter-width-base, $divider);
    bottom: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    top: math.div($grid-lg-gutter-width-base, $divider);
    right: math.div($grid-lg-gutter-width-base, $divider);
    bottom: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    top: math.div($grid-xl-gutter-width-base, $divider);
    right: math.div($grid-xl-gutter-width-base, $divider);
    bottom: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    top: math.div($grid-xxl-gutter-width-base, $divider);
    right: math.div($grid-xxl-gutter-width-base, $divider);
    bottom: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

// bottom: auto;
@mixin top-right-left($divider: 1) {
  top: math.div($grid-xs-gutter-width-base, $divider);
  right: math.div($grid-xs-gutter-width-base, $divider);
  left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    top: math.div($grid-sm-gutter-width-base, $divider);
    right: math.div($grid-sm-gutter-width-base, $divider);
    left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    top: math.div($grid-md-gutter-width-base, $divider);
    right: math.div($grid-md-gutter-width-base, $divider);
    left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    top: math.div($grid-lg-gutter-width-base, $divider);
    right: math.div($grid-lg-gutter-width-base, $divider);
    left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    top: math.div($grid-xl-gutter-width-base, $divider);
    right: math.div($grid-xl-gutter-width-base, $divider);
    left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    top: math.div($grid-xxl-gutter-width-base, $divider);
    right: math.div($grid-xxl-gutter-width-base, $divider);
    left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

// top: auto;
@mixin right-bottom-left($divider: 1) {
  right: math.div($grid-xs-gutter-width-base, $divider);
  bottom: math.div($grid-xs-gutter-width-base, $divider);
  left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    right: math.div($grid-sm-gutter-width-base, $divider);
    bottom: math.div($grid-sm-gutter-width-base, $divider);
    left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    right: math.div($grid-md-gutter-width-base, $divider);
    bottom: math.div($grid-md-gutter-width-base, $divider);
    left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    right: math.div($grid-lg-gutter-width-base, $divider);
    bottom: math.div($grid-lg-gutter-width-base, $divider);
    left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    right: math.div($grid-xl-gutter-width-base, $divider);
    bottom: math.div($grid-xl-gutter-width-base, $divider);
    left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    right: math.div($grid-xxl-gutter-width-base, $divider);
    bottom: math.div($grid-xxl-gutter-width-base, $divider);
    left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin right($divider: 1) {
  @if $divider > 0 {
    right: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      right: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      right: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      right: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      right: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      right: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    right: 0;

    @media #{$mobile} {
      right: 0;
    }

    @media #{$tablet} {
      right: 0;
    }

    @media #{$normal} {
      right: 0;
    }

    @media #{$wide} {
      right: 0;
    }

    @media #{$huge} {
      right: 0;
    }
  }
}

@mixin top($divider: 1) {
  @if $divider > 0 {
    top: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      top: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      top: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      top: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      top: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      top: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    top: 0;

    @media #{$mobile} {
      top: 0;
    }

    @media #{$tablet} {
      top: 0;
    }

    @media #{$normal} {
      top: 0;
    }

    @media #{$wide} {
      top: 0;
    }

    @media #{$huge} {
      top: 0;
    }
  }
}

@mixin bottom($divider: 1) {
  @if $divider > 0 {
    bottom: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      bottom: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      bottom: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      bottom: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      bottom: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      bottom: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    bottom: 0;

    @media #{$mobile} {
      bottom: 0;
    }

    @media #{$tablet} {
      bottom: 0;
    }

    @media #{$normal} {
      bottom: 0;
    }

    @media #{$wide} {
      bottom: 0;
    }

    @media #{$huge} {
      bottom: 0;
    }
  }
}

@mixin left($divider: 1) {
  @if $divider > 0 {
    left: math.div($grid-xs-gutter-width-base, $divider);

    @media #{$mobile} {
      left: math.div($grid-sm-gutter-width-base, $divider);
    }

    @media #{$tablet} {
      left: math.div($grid-md-gutter-width-base, $divider);
    }

    @media #{$normal} {
      left: math.div($grid-lg-gutter-width-base, $divider);
    }

    @media #{$wide} {
      left: math.div($grid-xl-gutter-width-base, $divider);
    }

    @media #{$huge} {
      left: math.div($grid-xxl-gutter-width-base, $divider);
    }
  } @else if $divider == 0 {
    left: 0;

    @media #{$mobile} {
      left: 0;
    }

    @media #{$tablet} {
      left: 0;
    }

    @media #{$normal} {
      left: 0;
    }

    @media #{$wide} {
      left: 0;
    }

    @media #{$huge} {
      left: 0;
    }
  }
}

@mixin left-right($divider: 1) {
  right: math.div($grid-xs-gutter-width-base, $divider);
  left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    right: math.div($grid-sm-gutter-width-base, $divider);
    left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    right: math.div($grid-md-gutter-width-base, $divider);
    left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    right: math.div($grid-lg-gutter-width-base, $divider);
    left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    right: math.div($grid-xl-gutter-width-base, $divider);
    left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    right: math.div($grid-xl-gutter-width-base, $divider);
    left: math.div($grid-xl-gutter-width-base, $divider);
  }
}

@mixin top-bottom($divider: 1) {
  top: math.div($grid-xs-gutter-width-base, $divider);
  bottom: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    top: math.div($grid-sm-gutter-width-base, $divider);
    bottom: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    top: math.div($grid-md-gutter-width-base, $divider);
    bottom: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    top: math.div($grid-lg-gutter-width-base, $divider);
    bottom: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    top: math.div($grid-xl-gutter-width-base, $divider);
    bottom: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    top: math.div($grid-xxl-gutter-width-base, $divider);
    bottom: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin left-top($divider: 1) {
  top: math.div($grid-xs-gutter-width-base, $divider);
  left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    top: math.div($grid-sm-gutter-width-base, $divider);
    left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    top: math.div($grid-md-gutter-width-base, $divider);
    left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    top: math.div($grid-lg-gutter-width-base, $divider);
    left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    top: math.div($grid-xl-gutter-width-base, $divider);
    left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    top: math.div($grid-xxl-gutter-width-base, $divider);
    left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin left-bottom($divider: 1) {
  bottom: math.div($grid-xs-gutter-width-base, $divider);
  left: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    bottom: math.div($grid-sm-gutter-width-base, $divider);
    left: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    bottom: math.div($grid-md-gutter-width-base, $divider);
    left: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    bottom: math.div($grid-lg-gutter-width-base, $divider);
    left: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    bottom: math.div($grid-xl-gutter-width-base, $divider);
    left: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    bottom: math.div($grid-xxl-gutter-width-base, $divider);
    left: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin right-top($divider: 1) {
  top: math.div($grid-xs-gutter-width-base, $divider);
  right: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    top: math.div($grid-sm-gutter-width-base, $divider);
    right: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    top: math.div($grid-md-gutter-width-base, $divider);
    right: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    top: math.div($grid-lg-gutter-width-base, $divider);
    right: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    top: math.div($grid-xl-gutter-width-base, $divider);
    right: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    top: math.div($grid-xxl-gutter-width-base, $divider);
    right: math.div($grid-xxl-gutter-width-base, $divider);
  }
}

@mixin right-bottom($divider: 1) {
  right: math.div($grid-xs-gutter-width-base, $divider);
  bottom: math.div($grid-xs-gutter-width-base, $divider);

  @media #{$mobile} {
    right: math.div($grid-sm-gutter-width-base, $divider);
    bottom: math.div($grid-sm-gutter-width-base, $divider);
  }

  @media #{$tablet} {
    right: math.div($grid-md-gutter-width-base, $divider);
    bottom: math.div($grid-md-gutter-width-base, $divider);
  }

  @media #{$normal} {
    right: math.div($grid-lg-gutter-width-base, $divider);
    bottom: math.div($grid-lg-gutter-width-base, $divider);
  }

  @media #{$wide} {
    right: math.div($grid-xl-gutter-width-base, $divider);
    bottom: math.div($grid-xl-gutter-width-base, $divider);
  }

  @media #{$huge} {
    right: math.div($grid-xxl-gutter-width-base, $divider);
    bottom: math.div($grid-xxl-gutter-width-base, $divider);
  }
}
