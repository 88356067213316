@use "assets/mixins" as *;

.NodeHomepage {
  &__hero {
    &__image {
      background-color: var(--water-blue-16);

      @media #{$normal} {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 21rem;
        object-fit: cover;

        @media #{$normal} {
          max-height: 32rem;
        }
      }
    }

    &__wrapper {
      margin-top: -4.6rem;
      padding-bottom: 6.4rem;

      @media #{$tablet} {
        margin-top: -4.8rem;
        padding-bottom: 7.2rem;
      }

      @media #{$normal} {
        margin-top: -7.2rem;
        padding-bottom: 9.6rem;
      }

      @media #{$wide} {
        margin-top: -9.6rem;
        padding-bottom: 12rem;
      }
    }

    // Design specs for the bar height are 1 px less
    // but that causes clipping issues.
    &__bar {
      display: flex;
      flex-direction: row;
      height: 4.7rem;

      @media #{$tablet} {
        height: 4.8rem;
      }

      @media #{$normal} {
        height: 7.3rem;
      }

      @media #{$wide} {
        height: 9.6rem;
      }

      .rectangle-left {
        position: absolute;
        width: 4.6rem;
        height: calc(100% + 0.1rem);
        margin-left: -4.6rem;
        background-color: var(--white);

        @media #{$tablet} {
          width: 4.8rem;
          margin-left: -4.8rem;
        }

        @media #{$normal} {
          width: 7.2rem;
          margin-left: -7.2rem;
        }

        @media #{$wide} {
          width: 9.6rem;
          margin-left: -9.6rem;
        }
      }

      .rectangle {
        width: 100%;
        height: calc(100% + 0.1rem);
        background-color: var(--white);
      }

      .triangle--homepage {
        position: relative;
        left: -0.1rem;
        width: 4.6rem;
        height: calc(100% + 0.1rem);
        margin-right: -2.3rem;
        background-color: var(--white);
        clip-path: polygon(0 0, 4% 0, 100% 100%, 0% 100%);

        @media #{$tablet} {
          width: 4.2rem;
          margin-right: -4.2rem;
        }

        @media #{$normal} {
          width: 6.4rem;
          margin-right: -4.1rem;
        }

        @media #{$wide} {
          width: 8.5rem;
          margin-right: 0;
        }
      }
    }

    &__title {
      // We don't want the title to come too close to the diagonal corner
      width: 90%;
    }

    &__body {
      @include p--body-large;

      margin-bottom: 2.4rem;

      @media #{$normal} {
        margin-bottom: 5.4rem;
      }

      :last-child {
        margin-bottom: 0;
      }
    }

    &__links {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;

      @media #{$normal} {
        gap: 2.4rem;
      }
    }
  }
}
