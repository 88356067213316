@use 'sass:map';

@import 'assets/variables';
@import 'assets/mixins';

.DownloadButton {
  display: flex;
  padding: map.get($spacers, 4) map.get($spacers, 6);
  border: 1px solid var(--air-blue);
  border-radius: 0;

  &__icon {
    display: flex;
    align-items: center;
    margin-right: map.get($spacers, 6);;
  }

  &__link {
    display: block;
    margin-bottom: map.get($spacers, 1);;
    font-weight: 600;
    color: var(--air-blue);
  }
}
