@use "sass:map";
@use "assets/variables" as *;

.NodeProjectPage {
  &__hero {
    position: relative;
    padding: 14rem 0;

    @media #{$tablet} {
      padding: 6rem 0;
    }

    &__image {
      height: 46rem;
      text-align: center;
      background-color: var(--grey);

      img {
        max-height: 38rem;
      }
    }

    &__text {

      margin: -8rem auto;
      padding: 8rem 10rem;
      background-color: var(--white);

      @media #{$mobile} {
        width: map.get($container-max-widths, sm);
      }

      @media #{$tablet} {
        width: map.get($container-max-widths, md);
      }

      @media #{$normal} {
        width: map.get($container-max-widths, lg);
      }

      @media #{$wide} {
        width: map.get($container-max-widths, xl);
      }
    }
  }

  &__paragraphs {
    margin: auto;
    padding: 2rem 8rem;

    @media #{$mobile} {
      width: map.get($container-max-widths, sm);
    }

    @media #{$tablet} {
      width: map.get($container-max-widths, md);
    }

    @media #{$normal} {
      width: map.get($container-max-widths, lg);
    }

    @media #{$wide} {
      width: map.get($container-max-widths, xl);
    }
  }
}
