@import "assets/variables";
@import "assets/mixins";

.DefaultProfilePage {
  position: relative;
  padding: 2.4rem 0;

  @media #{$normal} {
    margin-bottom: 0;
  }

  &__hero {
    padding-bottom: 6.4rem;

    @media #{$tablet} {
      padding-bottom: 9.6rem;
    }

    &__details {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 2.4rem;

      @media #{$tablet} {
        flex-direction: row;
        gap: 3.2rem;
        text-align: left;
        margin-bottom: 3.2rem;
      }
    }

    .WysiwygField {
      p {
        @include p--body-large;

        margin-bottom: 0;

        @media #{$normal} {
          margin-bottom: 0;
        }
      }
    }
  }

  &__image {
    width: 14rem;
    margin-bottom: 2.4rem;

    @media #{$tablet} {
      width: 16rem;
    }

    img {
      border-radius: 50%;
    }
  }

  &__description {
    p {
      @include p--body-large;
    }
  }

  &__links {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;

    @media #{$tablet} {
      justify-content: flex-start;
    }

    a {
      @include p--body-small;

      margin-bottom: 0;
      display: flex;
      gap: 0.4rem;
      align-items: center;

      @media #{$normal} {
        margin-bottom: 0;
      }
    }

    .icon {
      font-size: 2.4rem;
    }
  }
}
