@import "assets/mixins";

.ArticleMetadata {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem 0.8rem;
  align-content: center;
  align-items: center;
  margin-bottom: 1.6rem;
  padding-bottom: 0;

  @media #{$tablet} {
    margin-bottom: 2.4rem;
  }

  @media #{$normal} {
    margin-bottom: 3.2rem;
    column-gap: 1.6rem;
  }

  span {
    &:first-child {
      font-weight: 700;
    }

    &.dot {
      font-size: 1.8rem;
    }
  }
}
