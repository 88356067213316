@import "assets/mixins";

a.LinkTag {
  position: relative;
  display: inline-block;
  max-width: 22rem;
  max-height: 3rem;
  padding: 0.4rem 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: var(--air-blue, #005f87);
  text-overflow: ellipsis;
  white-space: nowrap;
  background: var(--water-blue-08, #f3f7f9);
  border-radius: 10rem;

  &:hover {
    text-decoration: none;
    background: var(--water-blue-16, #dce8ef);
  }

  &:focus-visible {
    outline: none;

    &:before {
      content: "";
      position: absolute;
      inset: -0.4rem;
      border: 2px solid var(--land-green, #00a977);
      border-radius: 10rem;
    }
  }

  span {
    overflow: hidden;
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .LinkTags__mainTheme & {
    background-color: var(--air-blue);
    color: var(--white);
  }
}
