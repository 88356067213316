@use "sass:map";
@import "assets/variables";
@import "assets/mixins";

.DefaultProfileTeaser {
  position: relative;
  background-color: var(--water-blue-08);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem 1.2rem;
  height: 100%;
  justify-content: flex-start;

  @media #{$tablet} {
    padding: 3.2rem 2rem;
  }

  @media #{$normal} {
    margin-bottom: 0;
  }

  &__image {
    width: map.get($spacers, 10);
    margin-bottom: map.get($spacers, 4);

    @media #{$tablet} {
      width: 12rem;
      margin-bottom: map.get($spacers, 6);
    }

    img {
      border-radius: 50%;
    }
  }

  &__description {
    p {
      @include p--body-small;
    }
  }

  &__links {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;

    a {
      @include p--body-small;

      margin-bottom: 0;
      display: flex;
      gap: 0.4rem;
      align-items: center;

      @media #{$normal} {
        margin-bottom: 0;
      }
    }

    .icon {
      font-size: map.get($spacers, 5);
    }
  }

  &.inline {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: map.get($spacers, 4);
    padding: map.get($spacers, 4);

    @media #{$tablet} {
      gap: map.get($spacers, 5);
      padding: map.get($spacers, 5);
    }

    .DefaultProfileTeaser__image {
      margin-bottom: 0;

      @media #{$tablet} {
        width: map.get($spacers, 11);
      }
    }

    .DefaultProfileTeaser__description {
      text-align: left;

      p {
        margin-bottom: 0.8rem;
      }
    }

    .DefaultProfileTeaser__links {
      justify-content: flex-start;
    }
  }
}
