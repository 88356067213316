@use "sass:map";
@import "assets/mixins";

.View {
  &__filters {
    display: flex;
    column-gap: map.get($spacers, 6);
    flex-wrap: wrap;

    @media #{$tablet} {
      flex-wrap: nowrap;
    }

    .FormElement {
      min-width: 33.5rem;
      margin-bottom: 0;
    }
  }

  &__Results {
    &__EmptyState {
      padding: 3.2rem 2.4rem;
      background-color: var(--water-blue-08);

      @media #{$normal} {
        padding: 4rem 6rem;
      }

      button {
        margin: 3rem auto 0;
      }
    }
  }

  .ViewFilters__filter {
    width: 100%;

    @media #{$normal} {
      width: auto;
    }
  }
}

.ViewFilters__filter {
  margin-bottom: 3.2rem;

  @media #{$normal} {
    margin-bottom: 4rem;
  }

  &.field_article_type_target_id .Select__inner {
    z-index: 99;
  }

  .Select__inner {
    z-index: 98;

    @media #{$normal} {
      max-width: 35rem;
    }

    &__single-value {
      width: 23rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
