@use "sass:map";

@import "assets/mixins";

.TeaserSmall {
  position: relative;

  &:hover {
    .TeaserSmall__heading {
      text-decoration: underline;
      color: var(--air-blue);
    }

    .TeaserSmall__image img {
      scale: 1.05;
      transition: all 0.2s ease-in-out;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    width: 8rem;
    height: 8rem;
    background-color: var(--water-blue-16);

    @media #{$normal} {
      left: 0;

      .image-right & {
        right: 0;
        left: unset;
      }
    }

    img {
      transition: all 0.2s ease-in-out;
      width: 100%;
      height: auto;
    }

    .isOfTypeCodeOrPublication {
      img {
        // The DynamicMediaItem has some logic that splits system names to aspect ratio
        // However this does not work for 1_1_41 so we have to overwrite it here
        // to prevent the img from getting a 1 / 1 ratio.
        // https://uncinc.monday.com/boards/99020894/pulses/6718705271
        /* stylelint-disable-next-line declaration-no-important */
        aspect-ratio: 1 / 1.41 !important;
      }
    }

    .overlay {
      .document & {
        width: 100%;
        height: 100%;
        background-color: rgba(#649BB9, 16%);
        position: absolute;
        overflow: hidden;
        z-index: 1;
      }
    }
  }

  &__info {
    display: flex;
    row-gap: 0.4rem;
    column-gap: 0.8rem;
    margin-bottom: 0.8rem;
    padding-right: 8.8rem;

    font-size: 1.4rem;
    font-style: normal;
    line-height: 2.2rem;
    color: var(--night-blue);

    @media #{$normal} {
      padding-right: unset;
      padding-left: 10.5rem;

      .image-right & {
        padding-right: 10.5rem;
        padding-left: unset;
      }
    }

    @media #{$mobile} {
      margin-bottom: 0.8rem;
    }

    &__type {
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
    }

    .TeaserSmall--noImage & {
      padding-right: unset;
      padding-left: unset;
    }
  }

  &__content {
    margin-right: 8.8rem;

    @media #{$normal} {
      margin-right: unset;
      margin-left: 10.5rem;

      .image-right & {
        margin-right: 10.5rem;
        margin-left: unset;
      }
    }

    .TeaserSmall--noImage & {
      margin-right: unset;
      margin-left: unset;
    }
  }

  &__summary {
    @include p--body-small;

    margin-bottom: 1.6rem;

    @media #{$normal} {
      margin-bottom: 1.6rem;
    }
  }

  &__heading {
    @include h4;

    margin-right: 8.8rem;

    @media #{$normal} {
      margin-right: unset;
    }

    a {
      display: block;

      &:hover {
        color: var(--air-blue);
        text-decoration: underline;
      }
    }

    .TeaserSmall--noImage & {
      margin-right: unset;
    }
  }

  a {
    &:after {
      content: "";
      inset: 0;
      position: absolute;
    }
  }

  .LinkTags {
    margin-top: 1rem;
    margin-bottom: 0;

    @media #{$normal} {
      margin-top: map.get($spacers, 4);
    }
  }
}
