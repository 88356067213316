@use "sass:map";
@use "assets/variables" as *;

@import "assets/variables";
@import "assets/mixins";

.NodeVacancyPage {
  margin-top: 1.6rem;

  @media #{$normal} {
    margin-top: 2.4rem;
  }

  &__hero {
    position: relative;
    padding: 4rem 0 6.4rem;

    @media #{$tablet} {
      padding: 4.8rem 0 7.2rem;
    }

    @media #{$normal} {
      padding: 4.8rem 0 9.6rem;
    }

    &__text {
      h1 {
        hyphens: auto;
      }
    }

    &__meta {
      display: flex;
      row-gap: 0.4rem;
      column-gap: 0.8rem;
      margin-bottom: 1.6rem;

      @media #{$tablet} {
        margin-bottom: 2.4rem;
      }

      @media #{$normal} {
        column-gap: 1.6rem;
        margin-bottom: 3.2rem;
      }

      &__type {
        font-weight: bold;
      }

      p {
        margin-bottom: 0;
      }
    }

    &__image {
      padding-top: 3.2rem;

      @media #{$tablet} {
        padding-top: 3.6rem;
      }

      .caption {
        margin-bottom: 0;
        padding-top: 1.6rem;
      }
    }

    .WysiwygField {
      p {
        @include p--body-large;
      }
    }
  }

  > .Share {
    margin-top: map.get($spacers, 9);
    padding-top: 0;

    @media #{$tablet} {
      margin-top: map.get($spacers, 10);
    }

    @media #{$normal} {
      margin-top: map.get($spacers, 11);
    }
  }
}
