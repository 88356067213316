@use "sass:map";

@import "assets/mixins";

*,
*:before,
*:after {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  overflow-y: auto;
  margin: 0;
  padding: 0;

  @media #{$normal} {
    // This is related to the fixed scrollbar issue.
    // See App/style.scss
    overflow-x: hidden;
  }
}

main {
  flex: 1;
}

// Allow for nested containers
.container .container {
  max-width: none;
  padding: 0;
}

li,
ul,
ol {
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.RecursiveMenu__layer__0,
.RecursiveMenu__layer__1,
.RecursiveMenu__layer__2 {
  margin: 0;
  padding: 0;
}

.dot {
  font-weight: bold;
}

// Base margin for paragraphs
.Paragraph:not(:last-child),
.Spacing--bottom:not(:last-child) {
  @include content-spacing;
}

// Base margin for paragraphs
.Node,
.DefaultProfilePage,
.Search {
  margin-bottom: 6.4rem;

  @media #{$tablet} {
    margin-bottom: 7.2rem;
  }

  @media #{$normal} {
    margin-bottom: 9.6rem;
  }

  @media #{$wide} {
    margin-bottom: 12rem;
  }
}

a.teaser-link:before {
  cursor: pointer;
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

p:last-child {
  margin-bottom: 0;
}

.Text {
  &__links {
    display: flex;
    flex-flow: row wrap;
    gap: map.get($spacers, 4);
    margin-top: map.get($spacers, 5);
    max-width: 100%;

    @media #{$normal} {
      gap: map.get($spacers, 5);
    }
  }

  &__read_more__button {
    font-weight: 600;
    color: var(--air-blue, #005f87);
    text-align: left;
    text-decoration: underline;
  }

  ul {
    margin-bottom: 3rem;
    margin-left: 3rem;

    li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &:before {
        content: "•";
        scale: 1.8;
        display: inline-block;
        position: absolute;
        left: 2rem;
        flex: 0 0 2.4rem;
        width: 2.4rem;
        margin-left: 1rem;
        color: var(--land-green);
      }
    }
  }

  ol {
    counter-reset: li;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 3rem;

    li {
      counter-increment: li;
      display: flex;
      align-items: start;

      &:before {
        content: counter(li);
        display: inline-block;
        flex: 0 0 3.4rem;
        width: 3.4rem;
        font-size: 2rem;
        font-weight: 700;
        font-style: normal;
        line-height: 2.8rem;
        color: var(--land-green);
      }
    }
  }

  table {
    @include p--body-small;

    max-width: 90vw;
    color: var(--night-blue, #002d41);

    caption {
      font-style: italic;
    }

    thead {
      th {
        padding: 0.6rem 1rem;
        font-weight: 600;
        background: var(--water-blue-08, #f3f7f9);
        border: 1px solid var(--water-blue-16, #e6eff4);
      }
    }

    tbody {
      td {
        padding: 0.6rem 1rem;
        background: var(--primary-white, #fff);
        border: 1px solid var(--water-blue-16, #e6eff4);
      }
    }
  }
}

.triangle {
  position: absolute;
  z-index: 2;
  right: -0.1rem;
  bottom: -0.1rem;
  width: 4.1rem;
  height: 4.8rem;
  background-color: var(--white);
  clip-path: polygon(0 100%, 100% 100%, 100% 0);

  @media #{$normal} {
    width: 8.1rem;
    height: 9.6rem;
  }

  &--small {
    width: 2.1rem;
    height: 2.7rem;
  }

  &--medium {
    width: 4.1rem;
    height: 4.8rem;
  }
}

// Temp overwrite of blur while image is loading.
// https: //uncinc.monday.com/boards/4834198144/pulses/5590312890.
img.AdaptivePicture:not(.AdaptivePicture--initiallyLoaded) {
  filter: unset;
}

// Hide any drupal status messages.
div[data-drupal-messages] {
  display: none;
}

.slick-slider {
  padding-bottom: 2rem;

  .slick-dots {
    margin-top: 4.8rem;

    li {
      &.slick-active {
        button:before {
          color: var(--land-green);
        }
      }

      button:before {
        opacity: 1;
        color: var(--water-blue-16);
        font-size: 10px;
      }
    }
  }
}

.GenericPage {
  padding: 4rem 0 6.4rem;

  @media #{$tablet} {
    padding: 4.8rem 0 7.2rem;
  }

  @media #{$normal} {
    padding: 5.6rem 0 7.2rem;
  }

  &__links {
    display: flex;
    gap: 1.6rem;
  }

  p {
    @include p--body-large;
  }
}

// when rendering a webform directly through the entity, give extra margin on the bottom
/* stylelint-disable-next-line selector-max-compound-selectors */
.Page>.page--inner-wrapper>.Container>div>form>.WebformComponent__form {
  margin-top: 4.8rem;
  margin-bottom: 6.4rem;

  @media #{$tablet} {
    margin-bottom: 7.2rem;
  }

  @media #{$normal} {
    margin-bottom: 9.6rem;
  }

  @media #{$wide} {
    margin-bottom: 12rem;
  }
}

.ContextualActionsButtons__actions {
  z-index: 100;
  margin-right: 3rem;
}

.Share__item .icon {
  background-color: transparent;
}
