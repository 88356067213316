@import "assets/variables";

@import "assets/mixins";

.MainMenu {
  position: relative;
  display: flex;
  align-items: center;
  height: $menu-item-height;
  padding: 0.8rem 0;
  color: var(--night-blue, #002d41);
  background-color: var(--white);
  border-top: 4px solid var(--water-blue, #649bb9);
  transition: 0.1s;

  @media #{$normal} {
    height: $menu-item-height-desktop;
  }

  &__logo {
    width: 10rem;
    margin-right: auto;

    @media #{$normal} {
      width: 13rem;
    }

    img {
      width: 100%;
    }
  }

  &__links {
    display: none;
    margin-right: 4.8rem;

    @media #{$normal} {
      display: flex;
    }

    .RecursiveMenu__layer__0 {
      display: flex;
      flex-wrap: wrap;
      gap: 3.2rem;
      align-content: center;
      align-items: center;
      margin: 0;

      .RecursiveMenu__item {
        font-weight: 600;

        &--active {
          .RecursiveMenu__title {
            text-decoration: 0.4rem underline var(--land-green);
            text-underline-offset: 0.42rem;
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 1.6rem;
    align-items: center;

    &__login.Button {
      display: none;

      @media #{$tablet} {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    &__search.Button {
      display: none;

      @media #{$tablet} {
        display: block;
        visibility: visible;
        opacity: 1;
      }

      .search-open & {
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.3rem;
          background-color: var(--land-green, #00a977);
        }
      }
    }
  }


  &.overlay-open {
    background: var(--water-blue-08, #f3f7f9);
    border-top: 4px transparent solid;
    transition: 0.1s;

    .MainMenu__buttons__login,
    .MainMenu__buttons__search,
    .MainMenu__links {
      visibility: hidden;
      opacity: 0;
    }
  }

  >.Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.SearchBar__inner {
  .TextField {
    input {
      padding: 0;
      background: none;
      border: var(--water-blue-08, #f3f7f9);
    }
  }
}

// select last three children with class RecursiveMenu__item of RecursiveMenu__layer__0
.menu--main>.RecursiveMenu__layer__0 .RecursiveMenu__item:nth-last-child(-n + 2) {
  &:not(.Overlay &) {
    display: none;
  }
}
