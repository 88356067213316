@import "assets/mixins";

.ArticleHighlights {
display: flex;

  &__Teasers {
    row-gap: 1.6rem;

    @media #{$tablet} {
      row-gap: 1.6rem;
    }

    @media #{$normal} {
      row-gap: 4rem;
    }
  }
}
