@import "assets/variables";
@import "assets/mixins";

.ProjectTeaser {
  margin-bottom: 2rem;

  @media #{$normal} {
    margin-bottom: 4rem;
  }

  &__heading {
    margin-top: 2rem;

    @media #{$normal} {
      margin-top: 3rem;
    };
  }

  &__content {
    margin-top: 2rem;

    & > span:first-of-type {
      display: block;
      margin-top: 1rem;
    }
  }

  &__blurb {
    margin-top: 1rem;

    @media #{$normal} {
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }

  &__image {
    overflow: hidden;
    border-radius: 1rem;

    @media #{$normal} {
      border-radius: 2rem;
    }
  }

  &--slide {
    max-width: 100%;
    padding: 0 1rem;
  }

  img {
    max-width: 100%;
  }
}
