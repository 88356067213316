@use "sass:map";

@import "assets/variables";
@import "assets/mixins";
@import "assets/icons";

.Search {
  margin-top: 4rem;

  @media #{$normal} {
    margin-top: 5.6rem;
  }

  &__item {
    padding: map.get($spacers, 3);
    background-color: var(--water-blue-16);
    border-radius: 0;
  }

  .Searchkit {
    &__Facets {
      form {
        display: flex;
        flex-direction: column;

        .FormElement {
          margin-bottom: 2.4rem;
        }

        .Checkboxes {
          &__inner {
            flex-flow: row wrap;
            gap: 0.8rem;
          }

          &__item {
            display: none;
          }
        }
      }
    }

    &__Facet {
      &__label {
        &__count {
          display: none;
        }
      }
    }

    &__Stats {
      padding-top: 1.3rem;

      @media #{$normal} {
        padding-top: 1.1rem;
      }
    }

    &__Header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.4rem;
      border-bottom: 1px solid var(--water-blue);

    }

    .SearchInput {
      padding-top: 3.2rem;
    }

    .SearchContent {
      padding-top: 4.8rem;

      @media #{$normal} {
        padding-top: 9.6rem;
      }
    }
  }

  .Searchkit__Results {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    @media #{$normal} {
      gap: 4rem;
    }

    &__EmptyState {
      padding: 3.2rem 2.4rem;
      background-color: var(--water-blue-08);

      @media #{$normal} {
        padding: 4rem 6rem;
      }

      button {
        margin: 3rem auto 0;
      }
    }
  }

  .asH4 {
    @include h4;
  }
}

.SearchBar {
  &__label {
    margin-bottom: 0;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.7rem;
  }

  &__inner {
    margin-top: 0.4rem;
    padding-bottom: 0.8rem;
    border-bottom: 2px solid rgba($color: #005f87, $alpha: 60%);

    .TextField {
      padding-right: 1.6rem;

      input {
        width: 100%;
        height: 5rem;
        padding: 0;
        color: var(--night-blue);
        background-color: transparent;
        border: 0;
      }
    }
  }

  &__submit {
    // Adds an search icon for styling purpose only.
    &:after {
      @media #{$tablet} {
        @include icon("search");
        @include icon-size("lg");

        content: "";
        flex: 0 0 auto;
        font-size: 3rem;
        background-position-y: -2em;
      }
    }

    &.Button--primary {
      color: var(--air-blue);
      background-color: var(--water-blue-08);

      &:hover {
        color: var(--air-blue);
        background-color: var(--water-blue-16);
      }
    }
  }
}

.SearchBarCustom {
  position: fixed;
  z-index: -1;
  top: $menu-item-height;
  right: 0;
  left: 0;
  transform: translateY(-100%);
  height: 13.5rem;
  padding: 2.4rem 2rem 3.2rem 2rem;
  visibility: hidden;
  background: var(--white, #fff);
  box-shadow: #fff;
  transition: 0.3s all ease-in-out;

  @media #{$normal} {
    top: $menu-item-height-desktop;
    transform: translateY(-100%);
    transition: 0.2s all ease-in-out;
  }

  .search-open & {
    z-index: -1;
    transform: translateY(0);
    visibility: visible;
    box-shadow: $box-shadow--hover;
    transition: 0.3s ease-in-out all;
  }

  .SearchBar__inner {
    .TextField {
      padding-right: 1.6rem;

      input {
        width: 100%;
        padding: 0;
        border: var(--white);
      }
    }
  }
}