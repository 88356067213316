@use 'sass:map';

@import "assets/variables";
@import "assets/mixins";

.ThemeTeaser {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--white);
  background: var(--night-blue);
  clip-path: polygon(93% 100%, 100% 93%, 100% 0%, 0% 0%, 0% 100%);

  @media #{$tablet} {
    clip-path: polygon(88% 100%, 100% 90%, 100% 0%, 0% 0%, 0% 100%);
  }

  &:hover {
    .ThemeTeaser__content__title h3 {
      text-decoration: underline;
    }

    .ThemeTeaser__image {
      transform: scale(1.05);
    }
  }

  &__image {
    overflow: hidden;
    width: 100%;
    transition: transform .2s;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: map.get($spacers, 5) map.get($spacers, 4) map.get($spacers, 5) map.get($spacers, 4);

    @media #{$normal} {
      padding: map.get($spacers, 6) map.get($spacers, 5) map.get($spacers, 6) map.get($spacers, 5);
    }

    &__title {
      h3 {
        margin-bottom: map.get($spacers, 4);
        hyphens: auto;
        color: var(--white);
        word-break: break-word;
      }
    }

    &__action {
      margin-top: map.get($spacers, 4);
      font-weight: bold;

      .icon {
        margin-left: 0.5rem;
        font-size: 3rem;
      }
    }
  }
}
