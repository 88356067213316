@use "assets/mixins" as *;

.Overlay {
  position: fixed;
  z-index: $z-index-2;
  top: $menu-item-height;
  left: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  display: flex;
  width: 100%;
  height: calc(100vh - $menu-item-height);
  padding: 2.4rem 0 3.2rem 0;
  visibility: hidden;
  opacity: 0;
  background: var(--water-blue-08, #f3f7f9);
  border-bottom: 4px solid var(--water-blue, #649bb9);
  transition: 0.3s;

  @media #{$tablet} {
    padding: 3.2rem 0 5.6rem 0;
  }

  @media #{$normal} {
    top: $menu-item-height-desktop;
    height: calc(100vh - $menu-item-height-desktop);
  }

  &__footer {
    margin-top: auto;
  }

  &-hamburger {
    color: var(--air-blue, #005f87);
  }

  .overlay-open & {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }

  .Container {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    width: 100%;
    height: fit-content;

    >.row {
      gap: 3.2rem;

      @media #{$tablet} {
        gap: unset;
      }

      &:first-child {
        @media #{$tablet} {
          margin-bottom: 4rem;
        }
      }
    }
  }

  .Button__login {
    width: fit-content;

    @media #{$tablet} {
      margin-top: 2.4rem;
    }
  }

  .RecursiveMenu__layer__0 {
    margin: 0;
    padding: 0;
  }

  .menu--main {
    .RecursiveMenu__layer__0 {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      align-items: flex-start;

      @media #{$tablet} {
        gap: 2.2rem;
      }

      @media #{$normal} {
        gap: 3.2rem;
      }
    }

    .RecursiveMenu__title {
      @include h3;

      color: var(--air-blue, #005f87);

      @media #{$tablet} {
        @include h2;

        font-size: 2.6rem;
        font-weight: 600;
        line-height: 130%;
      }

      @media #{$normal} {
        @include h2;
      }
    }
  }

  .menu--secondary-navigation {
    .RecursiveMenu__layer__0 {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      align-items: flex-start;

      @media #{$tablet} {
        gap: 1.8rem;
      }
    }

    .RecursiveMenu__title {
      @include p--body;

      @media #{$tablet} {
        font-weight: 500;
      }
    }
  }

  .menu--secondary-footer {
    .RecursiveMenu__layer__0 {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      align-content: center;
      align-items: center;
      padding: 0.3rem 0;
    }

    .RecursiveMenu__title {
      @include p--body-small;
    }
  }

  .SearchBar__inner {
    .TextField {
      width: 0;

      input {
        width: 100%;
      }
    }
  }

  .SearchBar__submit {
    padding: 1rem 1.6rem;
    color: var(--air-blue, #005f87);
    background: var(--white, #fff);

    &:hover {
      background: var(--water-blue-16, #f3f7f9);
    }

    &:focus-visible {
      background: var(--water-blue-16, #f3f7f9);
    }
  }
}
