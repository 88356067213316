@use "sass:map";
@import "assets/mixins";

.NodeArticlePage {
  display: flex;
  flex-direction: column;
  gap: map.get($spacers, 9);
  align-items: center;
  padding-top: 1.6rem;

  @media #{$tablet} {
    gap: map.get($spacers, 10);
  }

  @media #{$normal} {
    gap: map.get($spacers, 11);
  }

  @media #{$wide} {
    gap: map.get($spacers, 12);
  }

  &__hero {
    &__image {
      width: 100%;
      background-color: var(--water-blue-16, #e6eff4);

      &__caption {
        @include caption;

        margin: 1.6rem 0 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .WysiwygField {
      margin-bottom: 3.2rem;

      @media #{$tablet} {
        margin-bottom: 3.6rem;
      }

      p {
        @include p--body-large;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  &__paragraphs {
    max-width: 100%;
  }
}
