@import "assets/variables";

.ViewEventsUpcoming {
  display: flex;
  flex-direction: column;
  
  .NodeEvent {
    margin-bottom: 3rem;
  }
}
