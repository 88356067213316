@import "assets/variables";

.ViewPartner {
  padding: 1.6rem 0;

  .NodePartnerTeaserSmall {
    padding: 0;

    &__logo {
      gap: 0.8rem;
    }

    &__logo img {
      width: 7.2rem;

      @media #{$normal} {
        width: 11rem;
      }

    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @media #{$normal} {
      gap: 3rem;
    }
  }
}
