@use "sass:map";
@use "sass:list";

// New icon implementation
@import '~@uncinc/uncinc-react-kitchen-sink/dist/components/Icon/style';

// If you want to overwrite which material icons in the list, define icon names
$material-icon-names: (
  edit,
);

@include generateMaterialIconClasses;

// The base of an icon.
@mixin icon($icon-name: null, $icon-variant: null, $icon-orientation: null) {
  overflow: hidden;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 100%;
  text-indent: -9999px;
  vertical-align: middle;

  // The path here is defined starting from '/src/'.
  background-image: url('/assets/images/icons/icongrid.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: #{list.length($icon-names)}em #{$icon-rows}em;

  @if $icon-name {
    @include icon-name($icon-name);
  }

  @if $icon-variant {
    @include icon-variant($icon-variant);
  }

  @if $icon-orientation {
    @include icon-orientation($icon-orientation);
  }
}

// Define the following classes:
//   - icon
//   - icon-before
//   - icon-after
.icon,
.icon-before:before,
.icon-after:after {
  @include icon;
}

.icon-before:before,
.icon-after:after {
  content: '';
}

// Define classes based on the names (x-axis).
//
// Example:
//   - icon-facebook
//   - icon-twitter
@for $index from 1 through length($icon-names) {
  $name: list.nth($icon-names, $index);
  $offset-x: -#{$index - 1}em;

  .icon-#{$name}.icon {
    background-position-x: $offset-x;
  }

  .icon-#{$name}.icon-before:before {
    background-position-x: $offset-x;
  }

  .icon-#{$name}.icon-after:after {
    background-position-x: $offset-x;
  }
}

// Generate variants (y-axis) for all the icons.
//
// Example:
//   - icon-variant-1 (result: 'background-position-y: -1em')
//   - icon-variant-2 (result: 'background-position-y: -2em')
@for $index from 1 through ($icon-rows + 1) {
  $offset-y: -#{$index}em;

  .icon-variant-#{$index}.icon {
    background-position-y: $offset-y;
  }

  .icon-variant-#{$index}.icon-before:before {
    background-position-y: $offset-y;
  }

  .icon-variant-#{$index}.icon-after:after {
    background-position-y: $offset-y;
  }
}

// Generate classes for each orientation.
//
// Example:
//   - icon-orientation-left (result: 'transform: rotate(-180deg)')
//   - icon-orientation-up (result: 'transform: rotate(-90deg)')
@for $index from 1 through length($icon-orientations) {
  $orientation: list.nth(map.keys($icon-orientations), $index);
  $value: map.get($icon-orientations, $orientation);

  .icon-orientation-#{$orientation}.icon {
    transform: rotate(#{$value}deg);
  }

  .icon-orientation-#{$orientation}.icon-before:before {
    transform: rotate(#{$value}deg);
  }

  .icon-orientation-#{$orientation}.icon-after:after {
    transform: rotate(#{$value}deg);
  }
}