@use "sass:map";

@import "assets/mixins";

.ParagraphCTA {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background: var(--land-green, #00a977);

    @media #{$tablet} {
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
    }
  }

  &__wrapper__content {
    display: flex;
    flex-direction: column;
    gap: map.get($spacers, 4);
    align-items: start;
    width: 100%;
    padding: map.get($spacers, 6) map.get($spacers, 5);

    @media #{$tablet} {
      justify-content: center;
    }

    @media #{$normal} {
      padding: 5.6rem 6.4rem;
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;
      gap: map.get($spacers, 4);

      span {
        @include magic-line;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: map.get($spacers, 4);
    }
  }

  &__image {
    @media #{$mobile-only} {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media #{$tablet} {
        width: 28rem;
        max-height: 100%;
      }

      @media #{$normal} {
        width: 36rem;
      }
    }
  }

  &__title {
    @include h2;

    margin-bottom: 0;
    color: var(--white, #fff);

    @media #{$tablet} {
      margin-bottom: 0;
    }
  }

  .WysiwygField p {
    @include p--body-large;

    margin-bottom: 0;
    color: var(--night-blue, #002d41);

    @media #{$tablet} {
      margin-bottom: 0;
    }
  }

  .LazyLoadWrapper {
    width: 100%;
    height: 100%;
  }
}
