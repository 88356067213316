@use "sass:map";

@import "assets/variables";
@import "assets/mixins";
@import "assets/icons";

.Share {
  width: 100%;

  &__inner {
    display: flex;
    gap: 4rem;
  }

  &__socials {
    min-width: 26rem;
  }

  &__item {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }

    &__label {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2.2rem;
    }

    .icon {
      @include icon-size("lg");
      @include icon-color($color--night-blue);

      margin-right: 0.5rem;
    }
  }

  &__title {
    @include h4;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    row-gap: map.get($spacers, 2);
    column-gap: map.get($spacers, 4);

    @media #{$tablet} {
      width: 26rem;
    }
  }
}
