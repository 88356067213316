@use "sass:map";

@import "assets/mixins";

.TeaserBig {
  position: relative;

  &:hover {
    .TeaserBig__image img {
      scale: 1.05;
      transition: all 0.2s ease-in-out;
    }

    .TeaserBig__heading {
      color: var(--air-blue);
      text-decoration: underline;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    margin-bottom: 3.2rem;
    background-color: var(--water-blue-16);

    @media #{$normal} {
      min-height: 26rem;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      transition: all 0.2s ease-in-out;
    }

    .isOfTypeMagazine {
      img {
        // The DynamicMediaItem has some logic that splits system names to aspect ratio
        // However this does not work for 1_1_41 so we have to overwrite it here
        // to prevent the img from getting a 1 / 1 ratio.
        // https://uncinc.monday.com/boards/99020894/pulses/6718705271
        /* stylelint-disable-next-line declaration-no-important */
        aspect-ratio: 1 / 1.41 !important;
      }
    }
  }

  &__info {
    @include p--body-small;

    display: flex;
    row-gap: 0.4rem;
    column-gap: 0.8rem;
    margin-bottom: 1.6rem;
    color: var(--night-blue);

    @media #{$tablet} {
      margin-bottom: 2.4rem;
      padding-right: 0;
    }

    @media #{$normal} {
      column-gap: 0.8rem;
      margin-bottom: 1.6rem;
    }

    &__type {
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
      font-size: 1.4rem;
      color: var(--night-blue);
    }
  }

  &__heading {
    @include h3;

    a {
      display: block;
    }
  }

  &.highlighted {
    display: flex;
    flex-direction: column;

    @media #{$tablet} {
      flex-direction: row;
      gap: 2rem;

      > div {
        flex: 1;
      }
    }

    @media #{$normal} {
      gap: 3.2rem;

      .TeaserBig__image {
        flex: 0 0 66rem;
      }
    }

    @media #{$wide} {
      .TeaserBig__image {
        flex: 0 0 74rem;
      }
    }
  }

  &.large {
    @media #{$tablet} {
      display: flex;
      gap: 2rem;

      .TeaserBig__image,
      .TeaserBig__textBox {
        flex-basis: 50%;
        margin-bottom: 0;
      }
    }

    @media #{$normal} {
      gap: 4rem;
    }
  }

  a {
    &:after {
      content: "";
      position: absolute;
      inset: 0;
    }
  }

  .LinkTags {
    margin-top: 1rem;

    @media #{$normal} {
      margin-top: map.get($spacers, 4);
      margin-bottom: 0;
    }
  }
}
