@use "assets/mixins" as *;

.BackButton {
  cursor: pointer;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  margin-top: 1.6rem;
  margin-bottom: 4rem;

  @media #{$tablet} {
    margin-bottom: 4.8rem;
  }

  @media #{$normal} {
    margin-top: 2.4rem;
  }

  &__link {
    display: inline-flex;
    font-weight: 600;
    color: var(--water-blue);

    &:hover {
      color: var(--water-blue);
      text-decoration: none;
    }
  }

  span {
    font-size: 3rem;
  }
}
