@use "sass:map";

@import "assets/mixins";

.ArticleHighlight {
  margin-bottom: map.get($spacers, 6);

  @media #{$normal} {
    margin-bottom: map.get($spacers, 7);
  }
}
