@use "sass:map";

@import "assets/mixins";

.TaxonomyThemeParentPage {
  margin-bottom: map.get($spacers, 9);

  @media #{$normal} {
    margin-bottom: map.get($spacers, 11);

    .items-container {
      width: map.get($container-max-widths, lg);
    }
  }

  &__title {
    margin-top: map.get($spacers, 6);

    @media #{$normal} {
      margin-top: map.get($spacers, 9);
    }
  }

  &__body {
    margin-bottom: map.get($spacers, 9);

    @media #{$normal} {
      margin-bottom: map.get($spacers, 11);
    }

    .WysiwygField {
      @include p--body-large;
    }
  }

  &__dossiers {
    @include content-spacing;

    margin-left: auto;
    margin-right: auto;
  }

  .BlockNewsletterBlock{
    margin-top: 6rem;

    @media #{$tablet} {
      margin-top: 9.6rem
    }
  }
}
