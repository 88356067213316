@import "assets/mixins";

.ParagraphTextMedia {
  &__label {
    @include p--body-large;

    display: inline-block;
    margin-bottom: 1rem;
    color: var(--air-blue, #649bb9);

    @media #{$normal} {
      margin-bottom: 1.8rem;
    }
  }

  &--flipped {
    flex-direction: row-reverse;
  }

  img {
    width: 100%;
    background: var(--water-blue-16, #e6eff4);
  }

  .row {
    display: flex;
    gap: 4rem;

    @media #{$tablet} {
      gap: 0;
    }

    &.ParagraphTextMedia--top {
      gap: 4rem;
    }
  }

  .caption {
    margin-top: 1rem;
    font-style: italic;
    word-break: break-all;
  }

  .vjs-big-play-button {
    background: var(--water-blue, #e6eff4);
  }
}
