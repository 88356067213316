@use "sass:map";

@import "assets/variables";
@import "assets/mixins";

.Download {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  gap: map.get($spacers, 4);
  row-gap: map.get($spacers, 2);
  align-items: flex-start;
  padding: map.get($spacers, 4);
  text-decoration: none;
  position: relative;

  border-radius: 0.5rem;
  background: var(--water-blue-08, #f3f7f9);

  @media #{$tablet-down} {
    flex-direction: column;
    padding-left: 6.2rem;

    span.icon {
      position: absolute;
      left: 1.6rem;
    }
  }
  @media #{$normal} {
    padding: map.get($spacers, 5);
    flex-wrap: nowrap;
  }

  &:hover {
    border-radius: 0.5rem;
    background: var(--water-blue-16, #e6eff4);

    .icon {
      background-position-y: 0;
    }

    .Download__attributes {
      text-decoration: none;
    }
  }

  &__title {
    @include p--body-large;

    margin-bottom: 0;
    color: var(--night-blue);

    @media #{$normal} {
      margin-bottom: 0;
    }
  }

  &__icon.icon {
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
    background-position: -16em -4em;
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 1rem;
  }

  &__extension {
    flex: 0 0 auto;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__attributes {
    @include caption;

    display: flex;
    gap: map.get($spacers, 2);
    width: 100%;
    margin-left: 4.6rem;
    justify-content: flex-end;

    @media #{$tablet-down} {
      margin-left: 0;
      justify-content: flex-start;
    }

    @media #{$normal} {
      flex-basis: 10rem;
    }
  }
}

.Downloads__files {
  display: flex;
  flex-direction: column;
  gap: map.get($spacers, 3);
  margin-top: 3rem;

  @media #{$normal} {
    margin-top: 4rem;
  }
}

a.Download {
  &:hover {
    text-decoration: none;

    .Download__title {
      text-decoration: underline;
    }
  }
}
