@import "assets/variables";

.ViewPartnersHighlights {
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 0;
    align-items: center;

    @media #{$normal} {
      gap: 4rem;
      justify-content: flex-start;
    }
  }
}
