@use "sass:map";

@import "assets/mixins";

.TaxonomyThemePage {
  &__hero__image {
    background: var(--water-blue-16);
    clip-path: polygon(88% 100%, 100% 78%, 100% 0%, 0% 0%, 0% 100%);

    @media #{$tablet} {
      clip-path: polygon(94% 100%, 100% 70%, 100% 0%, 0% 0%, 0% 100%);
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    margin-top: map.get($spacers, 6);
  }

  &__body {
    @include content-spacing;

    .WysiwygField {
      @include p--body-large;
    }
  }
}
