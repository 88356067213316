@use 'sass:map';

@import "assets/variables";
@import "assets/mixins";

.ThemeOverviewTeaser {
  &:hover {
    .ThemeOverviewTeaser__image img{
      transform: scale(1.05);
    }
  }

  &__child_link {
    @include button-text;

    padding-top: 0.3rem;
    padding-bottom: 1rem;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: map.get($spacers, 5);
    background-color: var(--water-blue-16, #e6eff4);

    @media #{$normal} {
      margin-bottom: map.get($spacers, 6);
    }

    img {
      transition: transform .2s;
    }
  }

  &__content__link {
    display: flex;
    align-items: flex-start;

    .icon {
      min-width: 3rem;
      margin-right: 0.5rem;
      margin-bottom: 1rem;
      font-size: 3rem;
    }
  }

  &__content__title {
    a h2:hover {
      color: var(--air-blue);
      text-decoration: underline;
    }
  }

  &__content__action {
    margin-top: map.get($spacers, 4);

    @media #{$normal} {
      margin-bottom: map.get($spacers, 5);
    }
  }

}
