@use "sass:map";

@import "assets/mixins";

.ParagraphUSP {
  text-align: left;

  &__buttons {
    display: flex;
    gap: map.get($spacers, 4);

    @media #{$tablet} {
      gap: map.get($spacers, 5);
    }
  }

  &__subparagraphs {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;

    @media #{$tablet} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }

    @media #{$normal} {
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem;
    }

    &--two {
      display: grid;

      @media #{$normal} {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }

      @media #{$wide} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
      }
    }

    &--four {
      @media #{$normal} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }

      @media #{$wide} {
        grid-template-columns: repeat(4, 1fr);
        gap: 4rem;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: map.get($spacers, 8);
    align-items: flex-start;

    @media #{$normal} {
      gap: map.get($spacers, 9);
    }

    > .row {
      width: 100%;
      text-align: left;

      @media #{$mobile} {
        text-align: center;
      }

      @media #{$normal} {
        text-align: left;
      }
    }
  }
}
