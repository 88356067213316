@import "assets/mixins";

.LinkTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem 0.8rem;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  margin-bottom: 2.4rem;

  @media #{$normal} {
    margin-bottom: 3.6rem;
  }
}
