@use "assets/variables" as *;

.ParagraphThemesSelected {
  .EntitiesHighlighted__children {
    .row {
      row-gap: 3.2rem;

      @media #{$tablet} {
        row-gap: 2.4rem;
      }

      @media #{$normal} {
        row-gap: 4rem;
      }
    }
  }

  .slick-slider {
    margin-bottom: 4.8rem;

    .col-12 {
      height: 100%;
    }
  }
}
