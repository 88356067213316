@import "assets/variables";
@import "bootstrap/scss/mixins";

button,
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
  padding: 0;
  font: inherit;
  color: inherit;
  background: none;
  border: 0;
  outline: inherit;
}

.Button, .Cookie__content__buttons .Cookie__content__buttons__manual {
  position: relative;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  width: fit-content;
  padding: 0.4rem 0;
  font-size: $font-size--button;
  font-weight: 600;
  line-height: $line-height--button;
  white-space: pre;
  border-radius: 3px;

  @media #{$mobile-only} {
    max-width: 100%;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:focus-visible {
    :after {
      content: "";
      position: absolute;
      inset: -0.4rem;
      border: 2px solid var(--land-green, #00a977);
      border-radius: 3px;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &--primary {
    padding: 1rem 1.6rem;
    color: var(--white, #fff);
    background: var(--air-blue, #005f87);

    &:hover {
      color: var(--white, #fff);
      text-decoration: none;
      background: var(--night-blue, #002d41);
    }

    &:focus-visible {
      color: var(--white, #fff);
      background: var(--night-blue, #002d41);
    }
  }

  &--secondary {
    padding: 1rem 1.6rem;
    color: var(--air-blue, #005f87);
    background: var(--water-blue-08, #f3f7f9);

    &:hover {
      color: var(--air-blue, #005f87);
      background: var(--water-blue-16, #dce8ef);
    }

    &:focus-visible {
      color: var(--air-blue, #005f87);
      background: var(--water-blue-16, #dce8ef);
    }
  }

  &--white {
    padding: 1rem 1.6rem;
    color: var(--air-blue, #005f87);
    background: var(--white, #fff);

    &:hover {
      background: var(--water-blue-16, #f3f7f9);
    }

    &:focus-visible {
      background: var(--water-blue-16, #f3f7f9);
    }
  }

  &--textual {
    padding: 0;
    transition: 0.3s;

    @media #{$mobile-only} {
      padding: 0 1rem;
    }

    &:hover {
      &:after {
        transform: translateX(0.2rem);
        transition: 0.3s;
      }
    }

    > span {
      @include magic-line($color: var(--black));

      padding: 0.5rem 0;
    }
  }

  &--CTA {
    padding: 1rem 1.6rem;
    color: var(--white, #fff);
    background: var(--night-blue, #002d41);

    &:hover {
      color: var(--white, #fff);
      text-decoration: none;
      background: #3d6175;
    }
  }

  &--full {
    width: 100%;
  }

  &.disabled {
    pointer-events: none;

    &:after {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background: var(--night-blue, #002d41);
      border-radius: 3px;
    }
  }

  &.icon-after,
  &.icon-before {
    &:after,
    &:before {
      font-size: 3rem;
      min-width: 3rem;
    }
  }

  .icon,
  &:after,
  &:before {
    font-size: 3rem;
    min-width: 3rem;
  }
}
