@import 'assets/variables';
@import 'assets/mixins';

.NodePartnerTeaser {
  height: 100%;
  padding: 2.4rem;
  background-color: var(--water-blue-08);
  clip-path: polygon(93% 100%, 100% 93%, 100% 0%, 0% 0%, 0% 100%);

  @media #{$tablet} {
    clip-path: polygon(88% 100%, 100% 90%, 100% 0%, 0% 0%, 0% 100%);
  }

  &__logo {
    padding-bottom: 2.4rem;
    text-align: center;

    img {
      aspect-ratio: 16/9;
      width: 16rem;
      object-fit: cover;

      @media #{$normal} {
        width: 18rem;
      }
    }
  }

  &__section {
    margin-bottom: 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      @include p--body-small;

      margin-bottom: 0.4rem;
      font-weight: 600;
      color: var(--air-blue);

      @media #{$normal} {
        margin-bottom: 0.4rem;
      }
    }

    span {
      font-size: 2.6rem;
    }
  }

  &__address {
    &__details {
      @include p--body-small;

      display: flex;
      gap: 0.4rem;
      align-items: center;
    }
  }

  &__contact {
    &__details {
      p {
        @include p--body-small;

        margin-bottom: 0.6rem;

        @media #{$normal} {
          margin-bottom: 0.6rem;
        }
      }

      a {
        display: flex;
        gap: 0.4rem;
        align-items: center;

        &:hover {
          span {
            background-position-y: 0;
          }
        }
      }
    }
  }

  .WysiwygField {
    p {
      @include p--body-small;
    }
  }
}
