@use "sass:map";

@import "assets/mixins";

.ParagraphUSPSubparagraph {
  overflow: hidden;
  text-align: left;

  &__title {
    @include h1;

    overflow: hidden;
    margin-bottom: 1rem;
    color: var(--land-green, #00a977);
    text-overflow: ellipsis;

    @media #{$mobile} {
      margin-bottom: 1rem;
    }
  }

  &__image {
    aspect-ratio: 1/1;
    width: 11.5rem;
    margin: 0 auto 2rem;
    background-color: var(--grey);

    @media #{$tablet} {
      width: 16rem;
    }
  }

  .WysiwygField {
    p:first-child {
      margin-bottom: 0.8rem;
    }

    strong {
      @include h4;
    }
  }
}
