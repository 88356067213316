@import "assets/variables";

.ViewThemesByParent {
  display: grid;
  row-gap: 3.2rem;
  grid-template-columns: 1fr;
  max-width: 100%;

  @media #{$tablet} {
    grid-template-columns: 1fr 1fr;
    row-gap: 2.4rem;
    column-gap: 2rem;
  }

  @media #{$normal} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
  }
}
