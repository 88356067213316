@use 'sass:map';

@import 'assets/variables';
@import 'assets/mixins';
@import 'assets/icons';

.Breadcrumbs {
  margin: 0;
  padding: 0;
  margin-top: map.get($spacers, 5);

  &__item {
    @media #{$mobile-down} {
      display: none;

      &:nth-last-child(2) {
        display: block;

        &:before {
          @include icon('arrow-left');

          opacity: 1;
          font-size: 3rem;
          background-position-y: -3em;
          margin-right: 0.5rem;
        }

        a {
          color: var(--water-blue);
          font-weight: 600;
        }
      }
    }

    &:before {
      @include icon('chevron-right');

      padding-right: 1rem;
      padding-left: 1rem;
      opacity: 0.3;

      content: '';
      width: #{map.get($icon-sizes, 'md')}px;
      height: #{map.get($icon-sizes, 'md')}px;
      margin-right: map.get($spacers, 3);
      margin-left: 0;

      @media #{$tablet} {
        margin-left: map.get($spacers, 3);
      }
    }

    &:first-child {
      @media #{$tablet} {
        &:before {
          display: none;
        }
      }
    }

    &:last-child {
      a, span {
        color: var(--night-blue);
        opacity: 0.3;
        pointer-events: none;
      }
    }

    a, span {
      color: var(--night-blue);
      text-decoration: none;
    }
  }
}