@use "assets/variables" as *;
@import "assets/mixins";

.NodeLandingPage {
  &__hero {
    &__image {
      background-color: var(--water-blue-16);

      img {
        width: 100%;
        height: auto;
      }
    }

    &__wrapper {
      margin-top: -4.6rem;
      padding-bottom: 6.4rem;

      @media #{$tablet} {
        margin-top: -4.8rem;
        padding-bottom: 7.2rem;
      }

      @media #{$normal} {
        margin-top: -7.2rem;
        padding-bottom: 9.6rem;
      }

      @media #{$wide} {
        margin-top: -9.6rem;
        padding-bottom: 12rem;
      }

      &__text {
        margin-top: -1.6rem;

        @media #{$tablet} {
          margin-top: -0.8rem;
        }

        @media #{$normal} {
          margin-top: -2.4rem;
        }

        @media #{$wide} {
          margin-top: -3.2rem;
        }

        &__title {
          // We don't want the title to come too close to the diagonal corner
          width: 90%;
        }
      }

      &__body {
        @include p--body-large;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    // Design specs for the bar height are 1 px less
    // but that causes clipping issues.
    &__bar {
      display: flex;
      flex-direction: row;
      height: 4.7rem;

      @media #{$tablet} {
        height: 4.8rem;
      }

      @media #{$normal} {
        height: 7.3rem;
      }

      @media #{$wide} {
        height: 9.6rem;
      }

      .rectangle {
        width: 100%;
        background-color: var(--white);
      }

      .triangle-right {
        position: relative;
        left: -0.1rem;
        width: 4rem;
        height: 101%;
        background-color: var(--white);
        clip-path: polygon(0 0, 4% 0, 100% 100%, 0% 100%);

        @media #{$tablet} {
          width: 4.2rem;
        }

        @media #{$normal} {
          width: 6.4rem;
        }

        @media #{$wide} {
          width: 8.4rem;
          margin-right: -4rem;
        }
      }
    }
  }
}
