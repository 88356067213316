@import "assets/variables";
@import "assets/mixins";

body {
  @include font-size($font-size--body);
  @include font--primary;

  font-weight: normal;
  line-height: $line-height--body;
  color: var(--night-blue, #002d41);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @media #{$normal} {
    @include font-size($desktop--font-size--body);

    line-height: $desktop--line-height--body;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: normal;
  color: var(--night-blue, #002d41);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

h1,
.asH1 {
  @include h1;
}

h2,
.asH2 {
  @include h2;
}

h3,
.asH3 {
  @include h3;
}

h4,
.asH4 {
  @include h4;
}

.caption {
  @include caption;

  margin-top: 1rem;
}

// inline links
.WysiwygField,
.TitleWithText__text,
.NodeLandingPage__hero__wrapper__body,
.NodeHomepage__hero__body
 {
  a {
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      color: var(--land-green, #00a977);
      text-decoration: none;
    }

    &:active {
      color: var(--land-green, #00a977);
    }

    &:focus-visible {
      border: 2px solid var(--land-green, #00a977);
      border-radius: 0.3rem;
      outline: none;
    }
  }
}
