@use "sass:map";

@import "assets/mixins";

.QuickLinks {
  margin-top: map.get($spacers, 8);

  &__title {
    @include h3;
  }

  &__wrapper {
    height: 100%;
  }

  ul {
    width: 100%;
    margin-top: map.get($spacers, 4);
  }

  li {
    @include button-text;

    cursor: pointer;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eaeaea;

    &:before {
      flex: 0 0 3rem;
      font-size: 3rem;
    }

    a {
      padding: 1.4rem 0;
    }
  }
}
