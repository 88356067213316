@use 'sass:map';

@import "assets/variables";
@import "assets/mixins";

.ViewThemesOverview {
  &__item {
    margin-bottom: map.get($spacers, 6);
  }
}
