@import 'assets/variables';
@import 'assets/mixins';

.NodePartnerTeaserSmall {
  &__logo {
    text-align: center;

    img {
      width:100%;

      @media #{$tablet} {
        width: 15.4rem;
      }

      @media #{$normal} {
        width: 16rem;
      }
    }

  }
}
