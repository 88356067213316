@import "assets/mixins";

.NodeBasicPage {
  &__hero {
    padding: 4rem 0 6.4rem;

    @media #{$tablet} {
      padding: 4.8rem 0 7.2rem;
    }

    @media #{$normal} {
      padding: 5.6rem 0 9.6rem;
    }

    .WysiwygField {
      p {
        @include p--body-large;
      }
    }
  }

  &__links {
    margin-top: 2.4rem;

    @media #{$mobile-down} {
      button:last-of-type {
        margin-top: 2.4rem;
      }
    }

    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      gap: 2.4rem;
    }

    @media #{$normal} {
      margin-top: 3.2rem;
    }
  }
}
