/* stylelint-disable selector-max-compound-selectors */
.Search,
.MapSearch {
  form {
    .FormElement {
      >label {
        color: var(--night-blue);
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0.4rem;
      }

      .Select__inner {
        border-radius: 0.3rem;
      }

      .Searchkit__Facet__MenuSelect__control {
        border: 1px solid rgb(0 45 65 / 20%);
      }
    }

    .Checkboxes__inner {
      gap: 0.8rem;

      .WebformCheckboxes__checkbox__input {
        padding: 0.6rem 1rem;
        background-color: var(--water-blue-08);
        display: flex;
        align-items: center;
        gap: 0.6rem;
        border-radius: 0.3rem;

        &:before {
          font-size: 2.6rem;
        }

        &.icon-variant-0 {
          &:before {
            background-position-y: 0;
            background-position-x: -18em;
          }
        }

        &.icon-variant-1 {
          &:before {
            background-position-y: -2em;
            background-position-x: -20em;
          }
        }

        .Searchkit__Facet__label {
          &__label {
            font-size: 1.6rem;
            color: var(--air-blue);
            font-weight: 500;
          }
        }
      }
    }

    .Searchkit {
      &__Stats {
        margin-top: 0.7rem;
      }
    }

    .Searchkit__SortBy {
      min-width: 19rem;
      margin-bottom: 0;

      @media #{$normal} {
        min-width: 23rem;
      }

      &__menu {
        z-index: 999;
      }

      &__value-container {
        padding-right: 0;
      }

      &__placeholder {
        font-weight: 600;
        color: var(--night-blue);
      }

      &__control {
        border: 0;

        &:hover {
          border: 0;
        }
      }

      &__indicator {
        color: var(--land-green);
        font-size: 3rem;
      }

      &__indicator-separator {
        display: none;
      }
    }
  }
}
