@use "assets/variables" as *;

.ParagraphDocumentsFiltered {
  .row {
    row-gap: 3.2rem;

    @media #{$tablet} {
      row-gap: 2.4rem;
    }

    @media #{$normal} {
      row-gap: 4rem;
    }
  }
}
