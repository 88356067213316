@use "sass:map";

@import "assets/mixins";

.Document {
  &__hero {
    &__body {
      @include content-spacing;

      p {
        @include p--body-large;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  &__wrapper {
    margin-bottom: map.get($spacers, 5);
    background-color: var(--water-blue-16);

    @media #{$tablet} {
      margin-bottom: map.get($spacers, 7);
    }
  }

  &__buttons {
    margin: map.get($spacers, 5) 0 map.get($spacers, 9);

    @media #{$tablet} {
      display: none;
      margin: map.get($spacers, 7) 0 map.get($spacers, 11);
    }
  }
}

.NodeDocumentPage {
  &.page-spacer {
    margin-top: 4rem;

    @media #{$tablet} {
      margin-top: 4.8rem;
    }
  }

  > .Share {
    @include content-spacing(true, true);
  }

  .EntitiesHighlighted__children > .row {
    row-gap: 3.2rem;

    @media #{$tablet} {
      row-gap: 2.4rem;
    }
  }
}

.PDFReader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

  &__loader {
    color: var(--night-blue-80);
    background-color: var(--white);
  }

  .react-pdf {
    &__Page.loadingPage {
      display: none;
    }

    &__message--loading {
      color: var(--night-blue-80);
    }
  }

  .ControlPanel {
    display: flex;
    gap: map.get($spacers, 6);
    margin: map.get($spacers, 5);
    padding: map.get($spacers, 3);
    color: var(--night-blue-80);
    background-color: var(--white);
    border-radius: map.get($spacers, 3);

    &__pageControl {
      display: flex;
      gap: map.get($spacers, 2);
      align-items: center;
      justify-content: space-between;
      width: auto;

      @media #{$normal} {
        gap: map.get($spacers, 3);
      }

      &__pageIndicator {
        display: flex;
        gap: map.get($spacers, 2);
        align-items: center;

        @media #{$normal} {
          gap: map.get($spacers, 3);
        }

        span {
          display: none;

          @media #{$tablet} {
            display: inline-block;
            white-space: nowrap;
          }
        }
      }

      &__zoomLevel {
        min-width: 4rem;
        font-size: 1.4rem;
        font-weight: 600;
        text-align: center;

        @media #{$normal} {
          font-size: map.get($spacers, 4);
        }
      }

      button {
        position: relative;
        padding: 0.4rem 0.6rem;
        font-size: 1.4rem;
        background-color: var(--water-blue-08);
        border-radius: 0.3rem;
        transition: all 0.1s ease;

        @media #{$normal} {
          padding: 0.4rem 0.8rem;
        }

        &:focus-visible {
          :after {
            content: "";
            position: absolute;
            inset: -0.4rem;
            border: 0.2rem solid var(--land-green, #00a977);
            border-radius: 0.3rem;
          }
        }

        &:hover:not(.disabled) {
          background-color: var(--water-blue-16);
        }

        .icon {
          font-size: 3rem;
        }

        .zoom-in {
          margin-right: map.get($spacers, 5);
        }
      }

      a {
        border-radius: 0.3rem;
      }

      input[type="number"] {
        width: 5.6rem;
        padding: 0.5rem 1.4rem;
        text-align: center;

        appearance: textfield;
        border: 1px solid rgb(0 45 65 / 20%);
        border-radius: 0.3rem;

        @media #{$normal} {
          width: 8rem;
        }

        &:focus {
          outline: none;
        }

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          margin: 0;
          appearance: none;
        }
      }

      .disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    div {
      &:last-of-type {
        @media #{$mobile-only} {
          display: none;
        }
      }
    }
  }
}
