@use "sass:map";

@import "assets/mixins";

.EntitiesHighlighted {
  position: relative;

  &__children {
    margin-top: map.get($spacers, 5);
    margin-bottom: map.get($spacers, 5);

    @media #{$tablet} {
      margin-top: map.get($spacers, 6);
      margin-bottom: map.get($spacers, 6);
    }
  }

  &__link {
    @include content-spacing;

    &:not(.left) {
      a {
        margin: 0 auto;
      }
    }
  }
}
