@use "sass:map";

// -----------------------------------------------------------------------------
//
// Grid utilities
//
// -----------------------------------------------------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px,
  xl: 1560px
);

$container-max-widths: (
  sm: 546px,
  md: 728px,
  lg: 1160px,
  xl: 1520px
);

$grid-gutter-width-base: 40px;
$grid-xs-gutter-width-base: 40px;
$grid-sm-gutter-width-base: 30px;
$grid-md-gutter-width-base: 40px;
$grid-lg-gutter-width-base: 40px;
$grid-xl-gutter-width-base: 40px;

$grid-gutter-widths: (
  xs: $grid-xs-gutter-width-base,
  sm: $grid-sm-gutter-width-base,
  md: $grid-md-gutter-width-base,
  lg: $grid-lg-gutter-width-base,
  xl: $grid-xl-gutter-width-base
);

$screen-sm: #{map.get($grid-breakpoints, sm)};
$screen-md: #{map.get($grid-breakpoints, md)};
$screen-lg: #{map.get($grid-breakpoints, lg)};
$screen-xl: #{map.get($grid-breakpoints, xl)};

$screen-phone: $screen-sm;
$screen-tablet: $screen-md;
$screen-desktop: $screen-lg;
$screen-lg-desktop: $screen-xl;

$screen-xs-max: #{map.get($grid-breakpoints, sm) - 1};
$screen-sm-max: #{map.get($grid-breakpoints, md) - 1};
$screen-md-max: #{map.get($grid-breakpoints, lg) - 1};
$screen-lg-max: #{map.get($grid-breakpoints, xl) - 1};

// Lazy media query variables.
$mobile: "screen and (min-width: #{$screen-sm})";
$tablet: "screen and (min-width: #{$screen-md})";
$normal: "screen and (min-width: #{$screen-lg})";
$wide: "screen and (min-width: #{$screen-xl})";
$mobile-only: "screen and (max-width: #{$screen-sm-max})";
$tablet-only: "screen and (min-width: #{$screen-md}) and (max-width: #{$screen-md-max})";
$normal-only: "screen and (min-width: #{$screen-lg}) and (max-width: #{$screen-lg-max})";
$mobile-down: "screen and (max-width: #{$screen-sm-max})";
$tablet-down: "screen and (max-width: #{$screen-md-max})";
$normal-down: "screen and (max-width: #{$screen-lg-max})";

// -----------------------------------------------------------------------------
//
// Spacing
//
// -----------------------------------------------------------------------------

$spacers: ();
$spacers: map.merge(
  (
    0: 0,
    1: 2px,
    2: 4px,
    3: 8px,
    4: 16px,
    5: 24px,
    6: 32px,
    7: 36px,
    8: 48px,
    9: 64px,
    10: 72px,
    11: 96px,
    12: 120px
  ),
  $spacers
);

$menu-item-height: 7.2rem;
$menu-item-height-desktop: 9.6rem;

$content-spacing-mobile: map.get($spacers, 9);
$content-spacing-tablet: map.get($spacers, 10);
$content-spacing-normal: map.get($spacers, 11);
$content-spacing-wide: map.get($spacers, 12);

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

// Primary fonts will be used for 90% of the text styles, mainly body text.
// Secondary fonts will be used mainly for headings.
$font--primary: "Poppins", sans-serif;

// DESKTOP: Font sizes (in pixels) / line-heights
$desktop--font-size--h1: 46;
$desktop--line-height--h1: 135%;
$desktop--margin-bottom--h1: 2.4rem;

$desktop--font-size--h2: 32;
$desktop--line-height--h2: 135%;
$desktop--margin-bottom--h2: 1.8rem;

$desktop--font-size--h3: 26;
$desktop--line-height--h3: 130%;
$desktop--margin-bottom--h3: 1.6rem;

$desktop--font-size--h4: 22;
$desktop--line-height--h4: 130%;
$desktop--margin-bottom--h4: 1rem;

$desktop--font-size--body--large: 20;
$desktop--line-height--body--large: 150%;
$desktop--margin-bottom--body--large: 3rem;

$desktop--font-size--body: 18;
$desktop--line-height--body: 160%;
$desktop--margin-bottom--body: 3rem;

$desktop--font-size--body--small: 16;
$desktop--line-height--body--small: 140%;
$desktop--margin-bottom--body--small: 2rem;

$desktop--font-size--button: 18;
$desktop--line-height--button: 120%;

// MOBILE: Font sizes (in pixels) / line-heights
$font-size--h1: 30;
$line-height--h1: 120%;
$margin-bottom--h1: 2rem;

$font-size--h2: 26;
$line-height--h2: 120%;
$margin-bottom--h2: 1.6rem;

$font-size--h3: 20;
$line-height--h3: 120%;
$margin-bottom--h3: 1rem;

$font-size--h4: 18;
$line-height--h4: 120%;
$margin-bottom--h4: 0.6rem;

$font-size--body--large: 18;
$line-height--body--large: 150%;
$margin-bottom--body--large: 2rem;

$font-size--body: 16;
$line-height--body: 160%;
$margin-bottom--body: 3rem;

$font-size--body--small: 14;
$line-height--body--small: 140%;
$margin-bottom--body--small: 2rem;

$font-size--button: 18;
$line-height--button: 120%;

$font-size--marker-cluster: 13;
$line-height--marker-cluster: 37px;

$font-size--tooltip-text: 12;
$line-height--tooltip-text: 140%;

// -----------------------------------------------------------------------------
//
// Colors
//
// -----------------------------------------------------------------------------

// Overwrite for icon-color mixin
$color--night-blue: #002d41;

:root {
  --night-blue: #002d41;
  --night-blue-80: #335767;
  --air-blue: #005f87;
  --water-blue: #649bb9;
  --water-blue-32: #cddfe9;
  --water-blue-16: #e6eff4;
  --water-blue-08: #f3f7f9;
  --black-40: rgba(#000, 0.4);
  --land-green: #00a976;
  --earth-brown: #a67c52;
  --gras-green: #c8d75a;
  --extra-high-voltage: #5b40aa;
  --extra-high-voltage-background: #c7b5fa3d;
  --high-voltage: #5a7c23;
  --high-voltage-background: #b5d99f3d;
  --medium-voltage: #946300;
  --medium-voltage-background: #ffd47e3d;
  --low-voltage: #005091;
  --low-voltage-background: #a9d8ff3d;
  --no-voltage-background: #b6b8b93d;
  --success: #34752e;
  --success-04: rgba(#34752e, 0.04);
  --warning: #b36200;
  --warning-04: rgba(#b36200, 0.04);
  --error: #a74029;
  --error-04: rgba(#a74029, 0.04);
  --white: #fff;
  --black: #000;
  --grey: #a3a3a3;
}

// -----------------------------------------------------------------------------
//
// Borders
//
// -----------------------------------------------------------------------------

$border-radius-base: 3px;

// -----------------------------------------------------------------------------
//
// Shadows
//
// -----------------------------------------------------------------------------

$box-shadow--hover: 0 0 16px 0 var(--water-blue-32, #cddfe9);

// -----------------------------------------------------------------------------
//
// Sizes
//
// -----------------------------------------------------------------------------

// Header
$height--header: 7.2rem;
$desktop--height--header: 9.6rem;

// -----------------------------------------------------------------------------
//
// z-index
//
// -----------------------------------------------------------------------------
$z-index-0: 0;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 100;
$z-index-5: 1000;
$z-index-6: 1200;

// -----------------------------------------------------------------------------
//
// Magic Line
//
// -----------------------------------------------------------------------------
$magic-line-height-mobile: 0.2rem;
$magic-line-height-desktop: 0.3rem;

$magic-line-thick-height-mobile: 0.3rem;
$magic-line-thick-height-desktop: 0.4rem;

$magic-line-thin-height-mobile: 0.2rem;
$magic-line-thin-height-desktop: 0.2rem;

// -----------------------------------------------------------------------------
//
// Icons
//
// -----------------------------------------------------------------------------

// Define the amount of rows.
$icon-rows: 10;

// Define the icons from left to right in the icongrid using a human-readable
// name. This will also be part of the classname.
$icon-names: (
  search,
  hamburger,
  hamburger-search,
  chevron-right,
  chevron-left,
  chevron-down,
  chevron-up,
  plus,
  minus,
  cross,
  check,
  arrow-right,
  arrow-left,
  arrow-down,
  arrow-top,
  arrow-top-right,
  download,
  radio,
  checkbox,
  radio-checked,
  checkbox-checked,
  quote,
  play,
  attachment,
  share,
  phone,
  mail,
  globe,
  pin,
  send,
  comment,
  bolt,
  info,
  facebook,
  linkedin,
  twitter,
  print,
  last_page,
  first_page,
  zoom_in,
  zoom_out
);

$icon-sizes: (
  s: 12,
  md: 16,
  lg: 24,
  xl: 50
);

// Define the possible icon orientations an icon can have. This way we can
// easily change the orientation of an arrow for example, and don't have to
// rely on an overloaded icongrid.
// You shouldn't have to change these.
$icon-orientations: (
  left: -180,
  right: 0,
  up: -90,
  down: 90
);
