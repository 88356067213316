@import "assets/variables";

.ViewPersonsOverview {
  .row {
    row-gap: 2.4rem;

    @media #{$normal} {
      row-gap: 4rem;
    }
  }
}

