@use "assets/variables" as *;

.NodeDetailPage {
  &__hero {
    position: relative;
    margin-bottom: 6rem;

    @media #{$normal} {
      margin-bottom: 8rem;
    }

    &___image {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: var(--grey);
    }
  }
}
