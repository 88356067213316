@use "sass:map";

@import "assets/mixins";

.Accordion {
  &__item {
    border-bottom: 1px solid var(--water-blue-16);

    &:not(&.collapsed) {
      padding-bottom: 3.2rem;
    }

    &:hover:is(&.collapsed) {
      .Accordion__item__title {
        color: var(--air-blue, #005f87);
        text-decoration: underline;
      }
    }

    &__content {
      > div {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        align-items: flex-start;
        justify-content: center;
      }
    }

    &__title {
      margin-bottom: 0;
      color: var(--air-blue, #005f87);

      button {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 2.4rem 0;
        gap: 1rem;

        .icon {
          flex: 0 0 3rem;
          font-size: 3rem;
          transition: all 0.3s ease-in-out;

          .collapsed & {
            transform: rotate(-180deg);
          }
        }
      }

      .collapsed & {
        color: var(--night-blue, #002d41);
      }
    }
  }
}
