@use "sass:math";
@use "sass:map";
@use "sass:color";

@import "assets/variables";

// -----------------------------------------------------------------------------
//
// Icons
//
// -----------------------------------------------------------------------------

// The name of an icon
@mixin icon-name($name) {
  $offset-x: -#{index($icon-names, $name) - 1}em;

  background-position-x: $offset-x;
}

// Get the icon size from the list of icon sizes in variables.
@mixin icon-size($size: "md") {
  $icon-size: map.get($icon-sizes, $size);

  @include font-size($icon-size);
}

// The variant of an icon
@mixin icon-variant($variant) {
  $offset-y: calc(-#{$variant}em + 1em);

  background-position-y: $offset-y;
}

// The orientation of an icon
@mixin icon-orientation($orientation) {
  transform: rotate(#{map.get($icon-orientations, $orientation)}deg);
}

@mixin icon-color($color: #000000, $opacity: 1) {
  $r: color.red($color) / 255;
  $g: color.green($color) / 255;
  $b: color.blue($color) / 255;
  $a: $opacity;

  // grayscale fallback if SVG from data url is not supported
  $lightness: color.lightness($color);

  // color filter
  $svg-filter-id: "recolor";

  filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);

  /* stylelint-disable */
  filter: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}');
  /* stylelint-enable */
}

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

@mixin font--primary($weight: normal, $style: normal) {
  font-family: $font--primary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font-size($size) {
  font-size: #{$size}px;
  font-size: #{math.div($size, 10)}rem;
}

@mixin h1 {
  @include font-size($font-size--h1);
  @include font--primary;

  margin-bottom: $margin-bottom--h1;

  font-weight: 600;
  line-height: $line-height--h1;

  @media #{$normal} {
    @include font-size($desktop--font-size--h1);

    margin-bottom: $desktop--margin-bottom--h1;

    line-height: $desktop--line-height--h1;
  }
}

@mixin h2 {
  @include font-size($font-size--h2);
  @include font--primary;

  margin-bottom: $margin-bottom--h2;

  font-weight: 600;
  line-height: $line-height--h2;

  @media #{$normal} {
    @include font-size($desktop--font-size--h2);

    margin-bottom: $desktop--margin-bottom--h2;

    line-height: $desktop--line-height--h2;
  }
}

@mixin h3 {
  @include font-size($font-size--h3);
  @include font--primary;

  margin-bottom: $margin-bottom--h3;

  font-weight: 600;
  line-height: $line-height--h3;

  @media #{$normal} {
    @include font-size($desktop--font-size--h3);

    margin-bottom: $desktop--margin-bottom--h3;

    line-height: $desktop--line-height--h3;
  }
}

@mixin h4 {
  @include font-size($font-size--h4);
  @include font--primary;

  margin-bottom: $margin-bottom--h4;

  font-weight: 600;
  line-height: $line-height--h4;

  @media #{$normal} {
    @include font-size($desktop--font-size--h4);

    margin-bottom: $desktop--margin-bottom--h4;

    line-height: $desktop--line-height--h4;
  }
}

// Body mixins

@mixin p--body {
  @include font-size($font-size--body);
  @include font--primary;

  margin-bottom: $margin-bottom--body;
  font-weight: 400;
  line-height: $line-height--body;
  color: var(--night-blue-80, #335767);

  @media #{$normal} {
    @include font-size($desktop--font-size--body);

    margin-bottom: $desktop--margin-bottom--body;

    line-height: $desktop--line-height--body;
  }
}

@mixin p--body-small {
  @include font-size($font-size--body--small);
  @include font--primary;

  margin-bottom: $margin-bottom--body--small;
  font-weight: 400;
  line-height: $line-height--body--small;
  color: var(--night-blue-80, #335767);

  @media #{$normal} {
    @include font-size($desktop--font-size--body--small);

    margin-bottom: $desktop--margin-bottom--body--small;

    line-height: $desktop--line-height--body--small;
  }
}

@mixin p--body-large {
  @include font-size($font-size--body--large);
  @include font--primary;

  margin-bottom: $margin-bottom--body--large;
  font-weight: 500;
  line-height: $line-height--body--large;
  color: var(--night-blue-80, #335767);

  @media #{$normal} {
    @include font-size($desktop--font-size--body--large);

    margin-bottom: $desktop--margin-bottom--body--large;
    line-height: $desktop--line-height--body--large;
  }
}

@mixin caption {
  @include font-size($font-size--body--small);
  @include font--primary;

  font-style: italic;
  line-height: $line-height--body--small;
  color: var(--night-blue, #002d41);
}

@mixin button-text {
  @include font-size($font-size--button);

  font-weight: 600;
  line-height: $line-height--button;
}

// Link mixins.
@mixin magic-line(
  $color: var(--land-green),
  $after: false,
  $height-mobile: $magic-line-height-mobile,
  $height-desktop: $magic-line-height-desktop
) {
  position: relative;
  text-decoration: none;

  @if $after {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: $height-mobile;
      background-color: $color;
      transition: width 0.3s ease-in-out;

      @media #{$normal} {
        height: $height-desktop;
      }
    }

    &:hover:after {
      width: 100%;
      transition: width 0.3s ease-in-out;
    }
    /* stylelint-disable-next-line at-rule-empty-line-before */
  } @else {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: $height-mobile;
      background-color: $color;
      transition: width 0.3s ease-in-out;

      @media #{$normal} {
        height: $height-desktop;
      }
    }

    &:hover:before {
      width: 100%;
      transition: width 0.3s ease-in-out;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.magic-line {
  @include magic-line;

  &--water-blue {
    @include magic-line($color: var(--water-blue));
  }

  &--after {
    @include magic-line($after: true);

    &--thin {
      @include magic-line(
        $after: true,
        $height-mobile: $magic-line-thin-height-mobile,
        $height-desktop: $magic-line-thin-height-desktop
      );
    }

    &--thick {
      @include magic-line(
        $after: true,
        $height-mobile: $magic-line-thick-height-mobile,
        $height-desktop: $magic-line-thick-height-desktop
      );
    }
  }

  &--thin {
    @include magic-line(
      $height-mobile: $magic-line-thin-height-mobile,
      $height-desktop: $magic-line-thin-height-desktop
    );
  }

  &--thick {
    @include magic-line(
      $height-mobile: $magic-line-thick-height-mobile,
      $height-desktop: $magic-line-thick-height-desktop
    );
  }
}

@mixin content-spacing($bottom: true, $top: false, $gap: false) {
  @if $top {
    margin-top: $content-spacing-mobile;
  }

  @if $bottom {
    margin-bottom: $content-spacing-mobile;
  }

  @media #{$tablet} {
    @if $top {
      margin-top: $content-spacing-tablet;
    }

    @if $bottom {
      margin-bottom: $content-spacing-tablet;
    }
  }

  @media #{$normal} {
    @if $top {
      margin-top: $content-spacing-normal;
    }

    @if $bottom {
      margin-bottom: $content-spacing-normal;
    }
  }

  @media #{$wide} {
    @if $top {
      margin-top: $content-spacing-wide;
    }

    @if $bottom {
      margin-bottom: $content-spacing-wide;
    }
  }
}
