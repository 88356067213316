@use "assets/variables" as *;

.NodeEvent {
  padding: 1rem;
  border: 0.2rem solid var(--grey);

  &__labels,
  &__audiences {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    &__label,
    &__audience {
      padding: 0.5rem;
      background-color: var(--grey);
      border-radius: 1rem;
    }
  }
}