@use "sass:map";

@import "assets/variables";
@import "assets/mixins";

.NodeOverviewPage {
  &__hero {
    max-width: 76rem;
    padding: 4rem 0 6.4rem;

    @media #{$tablet} {
      padding: 4.8rem 0 7.2rem;
    }

    @media #{$normal} {
      padding: 5.6rem 0 7.2rem;
    }

    .WysiwygField {
      @media #{$normal} {
        font-weight: 500;
      }

      p {
        @include p--body-large;
      }
    }
  }

  // Because on overview pages we only show one paragraph, we need to hardcode the margin-bottom in here
  .node--page--paragraphs-wrapper {
    @include content-spacing;
  }
}

div.NodeOverviewPage__hero .WysiwygField p:last-child {
  margin-bottom: 0;
}
