@use "sass:map";

@import "assets/mixins";

.NodeDocumentTeaser {
  &--Magazine {
    .TeaserBig__image {
      aspect-ratio: 0.7;
      width: 100%;
      margin-bottom: map.get($spacers, 4);
      background-color: var(--water-blue-16);

      clip-path: polygon(91% 100%, 100% 93%, 100% 0%, 0% 0%, 0% 100%);

      @media #{$normal} {
        margin-bottom: map.get($spacers, 5);
      }
    }

    .TeaserBig__info {
      margin-bottom: 0.8rem;
    }

    .TeaserBig__heading {
      @include h3;

      @media #{$normal} {
        @include h4;
      }
    }
  }

  .TeaserSmall {
    min-height: 11rem;
  }

  .TeaserSmall__heading {
    @include h4;
  }

  .TeaserSmall__image {
    width: 8rem;
    height: 11rem;

    .overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(#649BB9, 16%);
      position: absolute;
      overflow: hidden;
      z-index: 1;
    }
  }
}
