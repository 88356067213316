/* stylelint-disable max-nesting-depth */
/* stylelint-disable declaration-no-important */
@use "sass:map";

@import "assets/variables";
@import "assets/mixins";
@import "assets/icons";

// -----------------------------------------------------------------------------
//
// Input styling
//
// -----------------------------------------------------------------------------

input,
textarea {
  all: unset;
  box-sizing: border-box;
}

.WebformComponent__form {
  .FormElement {
    &__description {
      font-size: 1.4rem;

      font-style: normal;
      color: var(--dark-grey, #4a4a4a);
    }

    &__error {
      font-size: 1.4rem;
      font-weight: 500;
      font-style: normal;
      color: var(--system-error, #a74029);
    }

    &__inner {
      > input,
      > textarea {
        height: 5rem;
        padding: 1rem 1.4rem;
        color: var(--night-blue, #002d41);
        background: #fff;
        border: 1px solid rgba(#002d41, 0.2);
        border-radius: 0.3rem;
        font-size: 1.6rem;
        font-weight: 400;

        &:focus {
          border: 1px solid var(--water-blue, #649bb9);
          box-shadow: 0 0 4px 0 var(--water-blue, #649bb9);
        }
      }

      > textarea {
        height: unset;
      }
    }

    &.error {
      input {
        background: rgba(#a74029, 0.04);
        border: 1px solid rgba(#a74029, 0.4);
      }

      .FormElement__description {
        display: none;
      }
    }

    label {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.2;
    }
  }
}

.RadioButtons {
  .WebformRadios__radio__input {
    &:hover {
      cursor: pointer;

      &:before {
        filter: drop-shadow(0 0 4px #649bb9);
      }
    }

    &:before {
      margin-right: 0.8rem;
      font-size: 3rem;
    }

    &.icon-variant-1 {
      &:before {
        background-position-x: -19em;
        background-position-y: -4em;
      }
    }

    span {
      font-size: 1.6rem;
      line-height: 140%;
    }

    input {
      border: 0;
    }
  }
}

form {
  .WebformCheckboxes {
    &__checkbox__input {
      display: flex;
      align-items: center;
      gap: 0.6rem;

      &:hover {
        cursor: pointer;

        &:before {
          filter: drop-shadow(0 0 4px #649bb9);
        }
      }

      &:before {
        font-size: 3rem;
      }

      &.icon-variant-0 {
        &:before {
          background-position-y: 0;
          background-position-x: -18em;
        }
      }

      &.icon-variant-1 {
        &:before {
          background-position-y: -4em;
          background-position-x: -20em;
        }
      }

      input {
        border: 0;
      }
    }
  }
}

@mixin select-inner-menu {
  margin: 0;
  border-color: var(--water-blue, #649bb9);
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 0.3rem 0.3rem;
  border-top: 1px solid rgba(#649bb9, 40%);
  box-shadow: none;

  &-list {
    padding: 0;
  }
}

@mixin select-dropdown-indicator {
  padding: 0;

  &:after {
    @include icon("chevron-down");
    @include icon-size("lg");

    background-position-y: 0;
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
    content: "";
    cursor: pointer;
    flex: 0 0 auto;
  }
}

@mixin select-clear-indicator {
  visibility: hidden;

  &:after {
    @include icon("cross");
    @include icon-size("lg");

    visibility: visible;
    background-position-y: -2em;
    width: 3rem;
    height: 3rem;
    background-color: var(--water-blue-08, #f3f7f9);
    border-radius: 0.3rem;
    font-size: 3rem;
    content: "";
    cursor: pointer;
    flex: 0 0 auto;
  }

  &:hover {
    &:after {
      background-color: rgba(#a74029, 10%);
      background-position-y: -5em;
    }
  }
}

@mixin select-option {
  &--is-focused {
    cursor: pointer;
    background: var(--water-blue-16, #e6eff4);
  }

  &--is-selected {
    cursor: pointer ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--night-blue, #002d41) ;
    background: var(--water-blue-08, #f3f7f9) ;

    &:after {
      @include icon("check");
      @include icon-size("lg");

      background-position-y: -4em;
      width: 3rem;
      height: 3rem;
      background-color: var(--water-blue-08, #f3f7f9);
      border-radius: 0.3rem;
      font-size: 3rem;
      margin-left: auto;
      content: "";
      cursor: pointer;
      flex: 0 0 auto;
    }
  }
}

@mixin select-placeholder {
  font-size: 1.6rem;
  line-height: 140%;
}

@mixin select-multi-value {
  display: flex;
  padding: 0.2rem 0.8rem;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  border-radius: 0.3rem;
  background: var(--water-blue-08, #f3f7f9);

  &:hover {
    background-color: rgba(#a74029, 10%);

    .Select__inner__multi-value__remove {
      &:after {
        background-color: transparent;
        background-position-y: -5em;
      }
    }
  }

  &__label {
    font-size: 1.6rem;
    line-height: 140%;
  }

  &__remove {
    visibility: hidden;

    &:after {
      @include icon("cross");
      @include icon-size("lg");

      visibility: visible;
      background-position-y: -2em;
      width: 2rem;
      height: 2rem;
      background-color: var(--water-blue-08, #f3f7f9);
      border-radius: 0.3rem;
      font-size: 2rem;
      content: "";
      cursor: pointer;
      flex: 0 0 auto;
    }

    svg {
      display: none;
    }
  }
}

@mixin select-control {
  cursor: pointer;
  position: relative;
  min-height: 5rem;
  padding: 0 1.4rem;
  color: var(--night-blue, #002d41);
  background: #fff;
  border-color: rgba(#002d41, 0.2);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 0.3rem ;

  &--is-focused {
    border-color: var(--water-blue, #649bb9) ;
    box-shadow: none;
    border-width: 1px 1px 1px 1px;

    &:hover {
      border-color: var(--water-blue, #649bb9);
    }
  }

  &--menu-is-open {
    border-width: 1px 1px 0 1px;
    border-radius: 0.3rem 0.3rem 0 0 ;
  }
}

@mixin select-value-container {
  position: unset;
  padding: 0;
}

.WebformSelect,
.View__filters {
  .Select {
    &__inner {
      &__menu {
        @include select-inner-menu;
      }

      &__dropdown-indicator {
        @include select-dropdown-indicator;
      }

      &__clear-indicator {
        @include select-clear-indicator;
      }

      &__option {
        @include select-option;
      }

      &__placeholder {
        @include select-placeholder;
      }

      &__indicator {
        svg {
          display: none;
        }
      }

      &__indicator-separator {
        display: none;
      }

      &__multi-value {
        @include select-multi-value;
      }

      &__control {
        @include select-control;
      }

      &__value-container {
        @include select-value-container;
      }
    }
  }
}

.Searchkit .Searchkit__Facet__MenuSelect {
  &__menu {
    @include select-inner-menu;
  }

  &__dropdown-indicator {
    @include select-dropdown-indicator;
  }

  &__clear-indicator {
    @include select-clear-indicator;
  }

  &__option {
    @include select-option;
  }

  &__placeholder {
    @include select-placeholder;
  }

  &__indicator {
    svg {
      display: none;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__multi-value {
    @include select-multi-value;
  }

  &__control {
    @include select-control;
  }

  &__value-container {
    @include select-value-container;
  }
}

.Searchkit .Searchkit__SortBy{
  &__menu {
    @include select-inner-menu;

    z-index: 9999;
  }

  &__dropdown-indicator {
    @include select-dropdown-indicator;
  }

  &__clear-indicator {
    @include select-clear-indicator;
  }

  &__option {
    @include select-option;
  }

  &__placeholder {
    @include select-placeholder;
  }

  &__indicator {
    svg {
      display: none;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__multi-value {
    @include select-multi-value;
  }

  &__control {
    @include select-control;
  }

  &__value-container {
    @include select-value-container;
  }
}
