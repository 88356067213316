@use "assets/variables" as *;

.HamburgerButton {
  color: var(--air-blue, #005f87);

  @media #{$tablet} {
    color: var(--night-blue, #002d41);

    .icon-variant-2.icon {
      background-position-x: -1em;
      background-position-y: 0;
    }

    .overlay-open & {
      color: var(--air-blue, #005f87);

      .icon-variant-2.icon {
        background-position-x: -9em;
        background-position-y: -2em;
      }
    }
  }
}
