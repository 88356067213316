@import "assets/mixins";

.Pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 3.2rem;
  padding: 1.7rem 0;

  @media #{$normal} {
    margin-top: 4rem;
    padding: 2.7rem 0;
  }

  &__container {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    font-weight: 600;

    &:not([class]) {
      &:hover {
        background: var(--water-blue-08, #f3f7f9);

        a {
          color: var(--air-blue, #005f87);
          background: var(--water-blue-08, #f3f7f9);
        }
      }
    }

    &.active {
      color: var(--white);
      background: var(--air-blue, #005f87);

      a:not([href]) {
        color: var(--white);
        background: var(--air-blue, #005f87);
      }
    }

    &.Pagination__break a {
      pointer-events: none;
    }

    a:not([href]) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      color: var(--air-blue, #005f87);
    }
  }

  .previous {
    margin-right: auto;

    @media #{$tablet} {
      margin-left: 3rem;
    }
  }

  .next {
    margin-left: auto;

    @media #{$tablet} {
      margin-right: 4rem;
    }
  }

  .previous,
  .next {
    align-self: center;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 2.16rem */

    &.disabled {
      pointer-events: none;
      opacity: 0.5;

      &:hover {
        a {
          cursor: inherit;
          user-select: none;
        }
      }
    }

    span {
      @include magic-line($color: var(--black));

      margin: 0 0.5rem;
      padding: 0.5rem 0;
      color: var(--night-blue, #002d41);

      @media #{$mobile-only} {
        display: none;
      }
    }

    .Pagination__previous,
    .Pagination__next {
      display: contents;
    }

    .icon-after:after,
    .icon-before:before {
      font-size: 3rem;
    }
  }

  .previous .icon-before:before {
    top: -2px;
    transform: rotate(180deg);
  }
}
