@use "sass:map";

@import "assets/mixins";

.Quote {
  &__quote {
    margin-bottom: 1.5rem;

    @media #{$normal} {
      margin-bottom: 2rem;
    }
  }

  &__profileImage {
    overflow: hidden;
    width: 6.2rem;
    border-radius: 50%;

    @media #{$normal} {
      width: 6.6rem;
    }
  }

  &__writer {
    display: flex;
    gap: map.get($spacers, 4);
    align-items: center;

    @media #{$normal} {
      gap: map.get($spacers, 5);
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: map.get($spacers, 2);
      align-items: flex-start;
      justify-content: center;

      @media #{$normal} {
        gap: map.get($spacers, 3);
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  &__name {
    @include h4;
  }

  &__function {
    @include p--body-small;
  }

  blockquote {
    @include h3;

    margin-bottom: 1.5rem;

    @media #{$normal} {
      margin-bottom: 2rem;
    }
  }

  .icon-quote {
    margin-bottom: 2rem;
    font-size: 4rem;
    background-position-y: -4em;

    @media #{$tablet} {
      font-size: 4.8rem;
    }

    @media #{$normal} {
      margin-bottom: 3rem;
      font-size: 5.6rem;
    }
  }
}
