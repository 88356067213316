@use "assets/mixins" as *;

.FooterMenu {
  padding: 3.2rem 0;
  background: var(--water-blue-08, #f3f7f9);

  @media #{$mobile} {
    padding-bottom: 6.4rem;
  }

  @media #{$tablet} {
    padding: 6.4rem 0 4rem;
  }

    @media #{$normal} {
    padding: 6.4rem 0 3.2rem;
  }

  &__logo {
    display: flex;
    width: 13rem;
    margin-bottom: 3.2rem;

    @media #{$tablet} {
      width: 15.2rem;
    }

    @media #{$normal} {
      width: 16rem;
    }

    img {
      width: 100%;
    }
  }

  &__menu {
    @media #{$tablet} {
      grid-column: span 3;
    }
  }

  &__secondary-menu {
    margin-top: 3.2rem;
    opacity: 0.8;

    @media #{$mobile} {
      position: absolute;
      bottom: -3.2rem;
      left: 50%;
      translate: translateX(-50%);
      margin-left: 1rem;
    }

    @media #{$tablet} {
      position: unset;
      bottom: unset;
      left: unset;
      translate: unset;
      grid-column: span 4;
      margin-top: 0;
      margin-left: unset;
    }
  }

  .Container {
    @media #{$tablet} {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      row-gap: 4rem;
    }

    @media #{$normal} {
      gap: 4rem;
    }
  }

  .menu--secondary-footer {
    .RecursiveMenu__layer__0 {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-start;

      @media #{$tablet} {
        flex-flow: row wrap;
        gap: 2.4rem;
        justify-content: flex-end;
      }

      .RecursiveMenu__title {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.menu--footer {
  .RecursiveMenu__layer__0 {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    align-items: flex-start;

    @media #{$mobile} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.7rem;
    }

    @media #{$tablet} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media #{$normal} {
      gap: 4rem;
    }

    > li > .RecursiveMenu__title {
      padding: 3px 0;
      font-family: Helvetica, sans-serif;
      font-size: 18px;
      font-weight: 600;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .RecursiveMenu__layer__2 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.8rem;

    .RecursiveMenu__item:before {
      margin-right: 0.8rem;
      font-size: 2.4rem;
    }

    .RecursiveMenu__title {
      @include p--body-small;
      @include magic-line(
        $height-mobile: $magic-line-thin-height-mobile,
        $height-desktop: $magic-line-thin-height-desktop
      );
    }
  }
}
