@use "sass:map";

@import "assets/mixins";

.BlockNewsletterBlock {
  margin-bottom: map.get($spacers, 9);

  &__body {
    @include p--body-large;
    @media #{$tablet-down} {
      margin-bottom: map.get($spacers, 6);
    }
  }

  .WebformComponent__form .Button {
    background-color: var(--air-blue);
    color: var(--white);
    padding: 1.6rem;

    @media #{$tablet} {
      position: absolute;
      top: 2.2rem;
      right: 2rem;
      height: 5rem;
    }

    &:after {
      background-position-x: -29em;
      background-position-y: -1em;
    }

    &:hover {
      background-color: var(--night-blue);
    }
  }

}
