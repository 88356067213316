@import 'assets/variables';

.App {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  // When you have a mouse plugged in, you get a fixed scrollbar,
  // until you open your overlay menu and the scrollbar disappears,
  // and makes your entire page jump a few pixels.
  // setting this width 100vw on a couple places, fixes that issue.
  @media #{$normal} {
    width: 100vw;
  }
}

.SiteHeader__wrapper {
  height: $height--header;

  @media #{$normal} {
    height: $desktop--height--header;
  }
}


